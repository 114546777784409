import React, { useEffect } from 'react';

if (
	typeof window !== 'undefined' &&
	process.env.NEXT_PUBLIC_ENABLE_WHY_DID_YOU_RENDER === 'true'
) {
	console.log('why-did-you-render enabled');
	const whyDidYouRender = require('@welldone-software/why-did-you-render');
	whyDidYouRender(React, {
		trackAllPureComponents: true,
		// logOnDifferentValues: true,
	});
}

import dynamic from 'next/dynamic';
import Head from 'next/head';
import useMounted from 'src/hooks/useMounted';
import '@dialectlabs/react-ui/index.css';
import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css';
import '../styles/index.css';
import '../styles/dev.css';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import Env, { isDev } from '../environmentVariables/EnvironmentVariables'; // S3_BUCKET_DRIFT_PUBLIC_MAINNET,
import {
	// App,
	// AppContext,
	AppInitialProps,
} from 'next/app';
import { IPredictionMarketConfig } from 'src/@types/predictionMarkets';
import { DriftWindow } from 'src/window/driftWindow';
import LoggingService from 'src/utils/LoggingService';
// import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';

const AppWrapper = dynamic(() => import('src/components/AppWrapper'));

type CustomAppProps = {
	predictionMarketConfigs: IPredictionMarketConfig[];
};

function DriftApp({
	Component,
	pageProps,
}: // predictionMarketConfigs,
{
	Component: any;
	pageProps: AppInitialProps;
} & CustomAppProps) {
	const mounted = useMounted();

	// useEffect(() => {
	// 	PredictionMarketConfigs.setConfigs(predictionMarketConfigs);
	// }, [predictionMarketConfigs]);

	// initialize any global state
	useEffect(() => {
		if (!mounted) return;

		LoggingService.setDevLogging(isDev());

		DriftWindow.STARTUP_TIME_MS = Date.now();
	}, [mounted]);

	return (
		mounted && (
			<>
				<Head>
					<title>Drift</title>
					<link rel="preconnect" href="https://fonts.gstatic.com" />

					<meta
						name="viewport"
						content="width=device-width, initial-scale=1, minimum-scale=1"
					/>

					<meta
						name="description"
						content={
							'Drift brings on-chain, cross-margined perpetual futures to Solana. Making futures DEXs the best way to trade.'
						}
					/>

					<meta name="theme-color" content="#000000" />

					<link
						rel="icon"
						type="image/svg"
						href="/favicon-24x24.svg"
						sizes="24x24"
					/>
					<link
						rel="apple-touch-icon"
						sizes="180x180"
						href="/apple-touch-icon.png"
					/>

					<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />

					<meta name="apple-mobile-web-app-title" content="Drift" />
					<meta name="application-name" content="Drift"></meta>

					<meta name="msapplication-TileColor" content="#000000" />
					<meta name="msapplication-TileImage" content="/mstile-144x144.png" />
				</Head>

				<AppWrapper>
					<Component {...pageProps} />
				</AppWrapper>

				{/* Rendering this component at the root of the page ensures the 'icon-gradient' style is available for other SVGs to use as linear gradient stroke style*/}
				<svg width="0" height="0">
					<linearGradient
						id="dark-app-gradient"
						x1="100%"
						y1="50%"
						x2="0%"
						y2="50%"
						gradientTransform="rotate(0)"
					>
						<stop stopColor="#71CCE9" offset="0" />
						<stop stopColor="#9468F1" offset="0.5" />
						<stop stopColor="#E8A2A0" offset="1" />
					</linearGradient>
					<linearGradient
						id="light-app-gradient"
						x1="100%"
						y1="50%"
						x2="0%"
						y2="50%"
						gradientTransform="rotate(0)"
					>
						<stop offset="0" stopColor="#11C7E3" />
						<stop offset="0.5" stopColor="#9162F6" />
						<stop offset="1" stopColor="#F7648F" />
					</linearGradient>
					<linearGradient
						id="prize-gradient"
						x1="100%"
						y1="50%"
						x2="0%"
						y2="50%"
						gradientTransform="rotate(0)"
					>
						<stop stopColor="#FFDEAD" offset="-0.21%" />
						<stop stopColor="#FFCF52" offset="53.39%" />
						<stop stopColor="#FCA239" offset="106.99%" />
					</linearGradient>
				</svg>

				<Analytics />
				<SpeedInsights
					beforeSend={(data) => {
						if (Env.mainnetSpeedInsightsEnabled) return data;

						if (data.url.includes('app.drift.trade')) {
							return null; // this will ignore the event
						}
						return data; // this will send the event as is
					}}
				/>
			</>
		)
	);
}

// DriftApp.getInitialProps = async (
// 	context: AppContext
// ): Promise<CustomAppProps & AppInitialProps> => {
// 	const initialPropsPromise = App.getInitialProps(context);
// 	const predictionMarketConfigsPromise = fetch(
// 		`${S3_BUCKET_DRIFT_PUBLIC_MAINNET}/prediction-markets/config.json`
// 	).then((res) => res.json());

// 	const [ctx, predictionMarketConfigs] = await Promise.all([
// 		initialPropsPromise,
// 		predictionMarketConfigsPromise,
// 	]);

// 	return { ...ctx, predictionMarketConfigs };
// };

export default DriftApp;
