"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwapReduceOnly = exports.PlaceAndTakeOrderSuccessCondition = exports.DefaultOrderParams = exports.ModifyOrderPolicy = exports.PostOnlyParams = exports.LiquidationType = exports.LPAction = exports.TradeSide = exports.getVariant = exports.isOneOfVariant = exports.isVariant = exports.SettlePnlMode = exports.StakeAction = exports.SpotFulfillmentConfigStatus = exports.SettlePnlExplanation = exports.DepositExplanation = exports.SpotFulfillmentStatus = exports.SpotFulfillmentType = exports.OrderTriggerCondition = exports.OrderActionExplanation = exports.OrderAction = exports.OrderStatus = exports.MarketType = exports.OrderType = exports.OracleSource = exports.DepositDirection = exports.PositionDirection = exports.SpotBalanceType = exports.SwapDirection = exports.AssetTier = exports.ContractTier = exports.ContractType = exports.UserStatus = exports.InsuranceFundOperation = exports.SpotOperation = exports.PerpOperation = exports.MarketStatus = exports.ExchangeStatus = void 0;
const _1 = require(".");
// # Utility Types / Enums / Constants
var ExchangeStatus;
(function (ExchangeStatus) {
    ExchangeStatus[ExchangeStatus["ACTIVE"] = 0] = "ACTIVE";
    ExchangeStatus[ExchangeStatus["DEPOSIT_PAUSED"] = 1] = "DEPOSIT_PAUSED";
    ExchangeStatus[ExchangeStatus["WITHDRAW_PAUSED"] = 2] = "WITHDRAW_PAUSED";
    ExchangeStatus[ExchangeStatus["AMM_PAUSED"] = 4] = "AMM_PAUSED";
    ExchangeStatus[ExchangeStatus["FILL_PAUSED"] = 8] = "FILL_PAUSED";
    ExchangeStatus[ExchangeStatus["LIQ_PAUSED"] = 16] = "LIQ_PAUSED";
    ExchangeStatus[ExchangeStatus["FUNDING_PAUSED"] = 32] = "FUNDING_PAUSED";
    ExchangeStatus[ExchangeStatus["SETTLE_PNL_PAUSED"] = 64] = "SETTLE_PNL_PAUSED";
    ExchangeStatus[ExchangeStatus["PAUSED"] = 127] = "PAUSED";
})(ExchangeStatus = exports.ExchangeStatus || (exports.ExchangeStatus = {}));
class MarketStatus {
}
exports.MarketStatus = MarketStatus;
MarketStatus.INITIALIZED = { initialized: {} };
MarketStatus.ACTIVE = { active: {} };
MarketStatus.FUNDING_PAUSED = { fundingPaused: {} };
MarketStatus.AMM_PAUSED = { ammPaused: {} };
MarketStatus.FILL_PAUSED = { fillPaused: {} };
MarketStatus.WITHDRAW_PAUSED = { withdrawPaused: {} };
MarketStatus.REDUCE_ONLY = { reduceOnly: {} };
MarketStatus.SETTLEMENT = { settlement: {} };
MarketStatus.DELISTED = { delisted: {} };
var PerpOperation;
(function (PerpOperation) {
    PerpOperation[PerpOperation["UPDATE_FUNDING"] = 1] = "UPDATE_FUNDING";
    PerpOperation[PerpOperation["AMM_FILL"] = 2] = "AMM_FILL";
    PerpOperation[PerpOperation["FILL"] = 4] = "FILL";
    PerpOperation[PerpOperation["SETTLE_PNL"] = 8] = "SETTLE_PNL";
    PerpOperation[PerpOperation["SETTLE_PNL_WITH_POSITION"] = 16] = "SETTLE_PNL_WITH_POSITION";
    PerpOperation[PerpOperation["LIQUIDATION"] = 32] = "LIQUIDATION";
})(PerpOperation = exports.PerpOperation || (exports.PerpOperation = {}));
var SpotOperation;
(function (SpotOperation) {
    SpotOperation[SpotOperation["UPDATE_CUMULATIVE_INTEREST"] = 1] = "UPDATE_CUMULATIVE_INTEREST";
    SpotOperation[SpotOperation["FILL"] = 2] = "FILL";
    SpotOperation[SpotOperation["DEPOSIT"] = 4] = "DEPOSIT";
    SpotOperation[SpotOperation["WITHDRAW"] = 8] = "WITHDRAW";
    SpotOperation[SpotOperation["LIQUIDATION"] = 16] = "LIQUIDATION";
})(SpotOperation = exports.SpotOperation || (exports.SpotOperation = {}));
var InsuranceFundOperation;
(function (InsuranceFundOperation) {
    InsuranceFundOperation[InsuranceFundOperation["INIT"] = 1] = "INIT";
    InsuranceFundOperation[InsuranceFundOperation["ADD"] = 2] = "ADD";
    InsuranceFundOperation[InsuranceFundOperation["REQUEST_REMOVE"] = 4] = "REQUEST_REMOVE";
    InsuranceFundOperation[InsuranceFundOperation["REMOVE"] = 8] = "REMOVE";
})(InsuranceFundOperation = exports.InsuranceFundOperation || (exports.InsuranceFundOperation = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus[UserStatus["BEING_LIQUIDATED"] = 1] = "BEING_LIQUIDATED";
    UserStatus[UserStatus["BANKRUPT"] = 2] = "BANKRUPT";
    UserStatus[UserStatus["REDUCE_ONLY"] = 4] = "REDUCE_ONLY";
    UserStatus[UserStatus["ADVANCED_LP"] = 8] = "ADVANCED_LP";
})(UserStatus = exports.UserStatus || (exports.UserStatus = {}));
class ContractType {
}
exports.ContractType = ContractType;
ContractType.PERPETUAL = { perpetual: {} };
ContractType.FUTURE = { future: {} };
ContractType.PREDICTION = { prediction: {} };
class ContractTier {
}
exports.ContractTier = ContractTier;
ContractTier.A = { a: {} };
ContractTier.B = { b: {} };
ContractTier.C = { c: {} };
ContractTier.SPECULATIVE = { speculative: {} };
ContractTier.HIGHLY_SPECULATIVE = { highlySpeculative: {} };
ContractTier.ISOLATED = { isolated: {} };
class AssetTier {
}
exports.AssetTier = AssetTier;
AssetTier.COLLATERAL = { collateral: {} };
AssetTier.PROTECTED = { protected: {} };
AssetTier.CROSS = { cross: {} };
AssetTier.ISOLATED = { isolated: {} };
AssetTier.UNLISTED = { unlisted: {} };
class SwapDirection {
}
exports.SwapDirection = SwapDirection;
SwapDirection.ADD = { add: {} };
SwapDirection.REMOVE = { remove: {} };
class SpotBalanceType {
}
exports.SpotBalanceType = SpotBalanceType;
SpotBalanceType.DEPOSIT = { deposit: {} };
SpotBalanceType.BORROW = { borrow: {} };
class PositionDirection {
}
exports.PositionDirection = PositionDirection;
PositionDirection.LONG = { long: {} };
PositionDirection.SHORT = { short: {} };
class DepositDirection {
}
exports.DepositDirection = DepositDirection;
DepositDirection.DEPOSIT = { deposit: {} };
DepositDirection.WITHDRAW = { withdraw: {} };
class OracleSource {
}
exports.OracleSource = OracleSource;
OracleSource.PYTH = { pyth: {} };
OracleSource.PYTH_1K = { pyth1K: {} };
OracleSource.PYTH_1M = { pyth1M: {} };
OracleSource.PYTH_PULL = { pythPull: {} };
OracleSource.PYTH_1K_PULL = { pyth1KPull: {} };
OracleSource.PYTH_1M_PULL = { pyth1MPull: {} };
OracleSource.SWITCHBOARD = { switchboard: {} };
OracleSource.QUOTE_ASSET = { quoteAsset: {} };
OracleSource.PYTH_STABLE_COIN = { pythStableCoin: {} };
OracleSource.PYTH_STABLE_COIN_PULL = { pythStableCoinPull: {} };
OracleSource.Prelaunch = { prelaunch: {} };
OracleSource.SWITCHBOARD_ON_DEMAND = { switchboardOnDemand: {} };
class OrderType {
}
exports.OrderType = OrderType;
OrderType.LIMIT = { limit: {} };
OrderType.TRIGGER_MARKET = { triggerMarket: {} };
OrderType.TRIGGER_LIMIT = { triggerLimit: {} };
OrderType.MARKET = { market: {} };
OrderType.ORACLE = { oracle: {} };
class MarketType {
}
exports.MarketType = MarketType;
MarketType.SPOT = { spot: {} };
MarketType.PERP = { perp: {} };
class OrderStatus {
}
exports.OrderStatus = OrderStatus;
OrderStatus.INIT = { init: {} };
OrderStatus.OPEN = { open: {} };
class OrderAction {
}
exports.OrderAction = OrderAction;
OrderAction.PLACE = { place: {} };
OrderAction.CANCEL = { cancel: {} };
OrderAction.EXPIRE = { expire: {} };
OrderAction.FILL = { fill: {} };
OrderAction.TRIGGER = { trigger: {} };
class OrderActionExplanation {
}
exports.OrderActionExplanation = OrderActionExplanation;
OrderActionExplanation.NONE = { none: {} };
OrderActionExplanation.INSUFFICIENT_FREE_COLLATERAL = {
    insufficientFreeCollateral: {},
};
OrderActionExplanation.ORACLE_PRICE_BREACHED_LIMIT_PRICE = {
    oraclePriceBreachedLimitPrice: {},
};
OrderActionExplanation.MARKET_ORDER_FILLED_TO_LIMIT_PRICE = {
    marketOrderFilledToLimitPrice: {},
};
OrderActionExplanation.ORDER_EXPIRED = {
    orderExpired: {},
};
OrderActionExplanation.LIQUIDATION = {
    liquidation: {},
};
OrderActionExplanation.ORDER_FILLED_WITH_AMM = {
    orderFilledWithAmm: {},
};
OrderActionExplanation.ORDER_FILLED_WITH_AMM_JIT = {
    orderFilledWithAmmJit: {},
};
OrderActionExplanation.ORDER_FILLED_WITH_AMM_JIT_LP_SPLIT = {
    orderFilledWithAmmJitLpSplit: {},
};
OrderActionExplanation.ORDER_FILLED_WITH_LP_JIT = {
    orderFilledWithLpJit: {},
};
OrderActionExplanation.ORDER_FILLED_WITH_MATCH = {
    orderFilledWithMatch: {},
};
OrderActionExplanation.ORDER_FILLED_WITH_MATCH_JIT = {
    orderFilledWithMatchJit: {},
};
OrderActionExplanation.MARKET_EXPIRED = {
    marketExpired: {},
};
OrderActionExplanation.RISK_INCREASING_ORDER = {
    riskingIncreasingOrder: {},
};
OrderActionExplanation.ORDER_FILLED_WITH_SERUM = {
    orderFillWithSerum: {},
};
OrderActionExplanation.ORDER_FILLED_WITH_OPENBOOK_V2 = {
    orderFilledWithOpenbookV2: {},
};
OrderActionExplanation.ORDER_FILLED_WITH_PHOENIX = {
    orderFillWithPhoenix: {},
};
OrderActionExplanation.REDUCE_ONLY_ORDER_INCREASED_POSITION = {
    reduceOnlyOrderIncreasedPosition: {},
};
OrderActionExplanation.DERISK_LP = {
    deriskLp: {},
};
class OrderTriggerCondition {
}
exports.OrderTriggerCondition = OrderTriggerCondition;
OrderTriggerCondition.ABOVE = { above: {} };
OrderTriggerCondition.BELOW = { below: {} };
OrderTriggerCondition.TRIGGERED_ABOVE = { triggeredAbove: {} }; // above condition has been triggered
OrderTriggerCondition.TRIGGERED_BELOW = { triggeredBelow: {} }; // below condition has been triggered
class SpotFulfillmentType {
}
exports.SpotFulfillmentType = SpotFulfillmentType;
SpotFulfillmentType.EXTERNAL = { external: {} };
SpotFulfillmentType.MATCH = { match: {} };
class SpotFulfillmentStatus {
}
exports.SpotFulfillmentStatus = SpotFulfillmentStatus;
SpotFulfillmentStatus.ENABLED = { enabled: {} };
SpotFulfillmentStatus.DISABLED = { disabled: {} };
class DepositExplanation {
}
exports.DepositExplanation = DepositExplanation;
DepositExplanation.NONE = { none: {} };
DepositExplanation.TRANSFER = { transfer: {} };
DepositExplanation.BORROW = { borrow: {} };
DepositExplanation.REPAY_BORROW = { repayBorrow: {} };
class SettlePnlExplanation {
}
exports.SettlePnlExplanation = SettlePnlExplanation;
SettlePnlExplanation.NONE = { none: {} };
SettlePnlExplanation.EXPIRED_POSITION = { expiredPosition: {} };
class SpotFulfillmentConfigStatus {
}
exports.SpotFulfillmentConfigStatus = SpotFulfillmentConfigStatus;
SpotFulfillmentConfigStatus.ENABLED = { enabled: {} };
SpotFulfillmentConfigStatus.DISABLED = { disabled: {} };
class StakeAction {
}
exports.StakeAction = StakeAction;
StakeAction.STAKE = { stake: {} };
StakeAction.UNSTAKE_REQUEST = { unstakeRequest: {} };
StakeAction.UNSTAKE_CANCEL_REQUEST = { unstakeCancelRequest: {} };
StakeAction.UNSTAKE = { unstake: {} };
StakeAction.UNSTAKE_TRANSFER = { unstakeTransfer: {} };
StakeAction.STAKE_TRANSFER = { stakeTransfer: {} };
class SettlePnlMode {
}
exports.SettlePnlMode = SettlePnlMode;
SettlePnlMode.TRY_SETTLE = { trySettle: {} };
SettlePnlMode.MUST_SETTLE = { mustSettle: {} };
function isVariant(object, type) {
    return object.hasOwnProperty(type);
}
exports.isVariant = isVariant;
function isOneOfVariant(object, types) {
    return types.reduce((result, type) => {
        return result || object.hasOwnProperty(type);
    }, false);
}
exports.isOneOfVariant = isOneOfVariant;
function getVariant(object) {
    return Object.keys(object)[0];
}
exports.getVariant = getVariant;
var TradeSide;
(function (TradeSide) {
    TradeSide[TradeSide["None"] = 0] = "None";
    TradeSide[TradeSide["Buy"] = 1] = "Buy";
    TradeSide[TradeSide["Sell"] = 2] = "Sell";
})(TradeSide = exports.TradeSide || (exports.TradeSide = {}));
class LPAction {
}
exports.LPAction = LPAction;
LPAction.ADD_LIQUIDITY = { addLiquidity: {} };
LPAction.REMOVE_LIQUIDITY = { removeLiquidity: {} };
LPAction.SETTLE_LIQUIDITY = { settleLiquidity: {} };
LPAction.REMOVE_LIQUIDITY_DERISK = { removeLiquidityDerisk: {} };
class LiquidationType {
}
exports.LiquidationType = LiquidationType;
LiquidationType.LIQUIDATE_PERP = { liquidatePerp: {} };
LiquidationType.LIQUIDATE_BORROW_FOR_PERP_PNL = {
    liquidateBorrowForPerpPnl: {},
};
LiquidationType.LIQUIDATE_PERP_PNL_FOR_DEPOSIT = {
    liquidatePerpPnlForDeposit: {},
};
LiquidationType.PERP_BANKRUPTCY = {
    perpBankruptcy: {},
};
LiquidationType.SPOT_BANKRUPTCY = {
    spotBankruptcy: {},
};
LiquidationType.LIQUIDATE_SPOT = {
    liquidateSpot: {},
};
class PostOnlyParams {
}
exports.PostOnlyParams = PostOnlyParams;
PostOnlyParams.NONE = { none: {} };
PostOnlyParams.MUST_POST_ONLY = { mustPostOnly: {} }; // Tx fails if order can't be post only
PostOnlyParams.TRY_POST_ONLY = { tryPostOnly: {} }; // Tx succeeds and order not placed if can't be post only
PostOnlyParams.SLIDE = { slide: {} }; // Modify price to be post only if can't be post only
class ModifyOrderPolicy {
}
exports.ModifyOrderPolicy = ModifyOrderPolicy;
ModifyOrderPolicy.MUST_MODIFY = { mustModify: {} };
ModifyOrderPolicy.TRY_MODIFY = { tryModify: {} };
exports.DefaultOrderParams = {
    orderType: OrderType.MARKET,
    marketType: MarketType.PERP,
    userOrderId: 0,
    direction: PositionDirection.LONG,
    baseAssetAmount: _1.ZERO,
    price: _1.ZERO,
    marketIndex: 0,
    reduceOnly: false,
    postOnly: PostOnlyParams.NONE,
    immediateOrCancel: false,
    triggerPrice: null,
    triggerCondition: OrderTriggerCondition.ABOVE,
    oraclePriceOffset: null,
    auctionDuration: null,
    maxTs: null,
    auctionStartPrice: null,
    auctionEndPrice: null,
};
var PlaceAndTakeOrderSuccessCondition;
(function (PlaceAndTakeOrderSuccessCondition) {
    PlaceAndTakeOrderSuccessCondition[PlaceAndTakeOrderSuccessCondition["PartialFill"] = 1] = "PartialFill";
    PlaceAndTakeOrderSuccessCondition[PlaceAndTakeOrderSuccessCondition["FullFill"] = 2] = "FullFill";
})(PlaceAndTakeOrderSuccessCondition = exports.PlaceAndTakeOrderSuccessCondition || (exports.PlaceAndTakeOrderSuccessCondition = {}));
class SwapReduceOnly {
}
exports.SwapReduceOnly = SwapReduceOnly;
SwapReduceOnly.In = { in: {} };
SwapReduceOnly.Out = { out: {} };
