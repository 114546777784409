"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = void 0;
const types_1 = require("./types");
const position_1 = require("./math/position");
const numericConstants_1 = require("./constants/numericConstants");
const _1 = require(".");
const spotBalance_1 = require("./math/spotBalance");
const amm_1 = require("./math/amm");
const margin_1 = require("./math/margin");
const pollingUserAccountSubscriber_1 = require("./accounts/pollingUserAccountSubscriber");
const webSocketUserAccountSubscriber_1 = require("./accounts/webSocketUserAccountSubscriber");
const spotPosition_1 = require("./math/spotPosition");
const oracles_1 = require("./math/oracles");
const tiers_1 = require("./math/tiers");
const strictOraclePrice_1 = require("./oracles/strictOraclePrice");
const fuel_1 = require("./math/fuel");
class User {
    get isSubscribed() {
        return this._isSubscribed && this.accountSubscriber.isSubscribed;
    }
    set isSubscribed(val) {
        this._isSubscribed = val;
    }
    constructor(config) {
        var _a, _b, _c, _d, _e;
        this._isSubscribed = false;
        this.driftClient = config.driftClient;
        this.userAccountPublicKey = config.userAccountPublicKey;
        if (((_a = config.accountSubscription) === null || _a === void 0 ? void 0 : _a.type) === 'polling') {
            this.accountSubscriber = new pollingUserAccountSubscriber_1.PollingUserAccountSubscriber(config.driftClient.connection, config.userAccountPublicKey, config.accountSubscription.accountLoader, this.driftClient.program.account.user.coder.accounts.decodeUnchecked.bind(this.driftClient.program.account.user.coder.accounts));
        }
        else if (((_b = config.accountSubscription) === null || _b === void 0 ? void 0 : _b.type) === 'custom') {
            this.accountSubscriber = config.accountSubscription.userAccountSubscriber;
        }
        else {
            this.accountSubscriber = new webSocketUserAccountSubscriber_1.WebSocketUserAccountSubscriber(config.driftClient.program, config.userAccountPublicKey, {
                resubTimeoutMs: (_c = config.accountSubscription) === null || _c === void 0 ? void 0 : _c.resubTimeoutMs,
                logResubMessages: (_d = config.accountSubscription) === null || _d === void 0 ? void 0 : _d.logResubMessages,
            }, (_e = config.accountSubscription) === null || _e === void 0 ? void 0 : _e.commitment);
        }
        this.eventEmitter = this.accountSubscriber.eventEmitter;
    }
    /**
     * Subscribe to User state accounts
     * @returns SusbcriptionSuccess result
     */
    async subscribe(userAccount) {
        this.isSubscribed = await this.accountSubscriber.subscribe(userAccount);
        return this.isSubscribed;
    }
    /**
     *	Forces the accountSubscriber to fetch account updates from rpc
     */
    async fetchAccounts() {
        await this.accountSubscriber.fetch();
    }
    async unsubscribe() {
        await this.accountSubscriber.unsubscribe();
        this.isSubscribed = false;
    }
    getUserAccount() {
        return this.accountSubscriber.getUserAccountAndSlot().data;
    }
    async forceGetUserAccount() {
        await this.fetchAccounts();
        return this.accountSubscriber.getUserAccountAndSlot().data;
    }
    getUserAccountAndSlot() {
        return this.accountSubscriber.getUserAccountAndSlot();
    }
    getPerpPositionForUserAccount(userAccount, marketIndex) {
        return this.getActivePerpPositionsForUserAccount(userAccount).find((position) => position.marketIndex === marketIndex);
    }
    /**
     * Gets the user's current position for a given perp market. If the user has no position returns undefined
     * @param marketIndex
     * @returns userPerpPosition
     */
    getPerpPosition(marketIndex) {
        const userAccount = this.getUserAccount();
        return this.getPerpPositionForUserAccount(userAccount, marketIndex);
    }
    getPerpPositionAndSlot(marketIndex) {
        const userAccount = this.getUserAccountAndSlot();
        const perpPosition = this.getPerpPositionForUserAccount(userAccount.data, marketIndex);
        return {
            data: perpPosition,
            slot: userAccount.slot,
        };
    }
    getSpotPositionForUserAccount(userAccount, marketIndex) {
        return userAccount.spotPositions.find((position) => position.marketIndex === marketIndex);
    }
    /**
     * Gets the user's current position for a given spot market. If the user has no position returns undefined
     * @param marketIndex
     * @returns userSpotPosition
     */
    getSpotPosition(marketIndex) {
        const userAccount = this.getUserAccount();
        return this.getSpotPositionForUserAccount(userAccount, marketIndex);
    }
    getSpotPositionAndSlot(marketIndex) {
        const userAccount = this.getUserAccountAndSlot();
        const spotPosition = this.getSpotPositionForUserAccount(userAccount.data, marketIndex);
        return {
            data: spotPosition,
            slot: userAccount.slot,
        };
    }
    getEmptySpotPosition(marketIndex) {
        return {
            marketIndex,
            scaledBalance: numericConstants_1.ZERO,
            balanceType: _1.SpotBalanceType.DEPOSIT,
            cumulativeDeposits: numericConstants_1.ZERO,
            openAsks: numericConstants_1.ZERO,
            openBids: numericConstants_1.ZERO,
            openOrders: 0,
        };
    }
    /**
     * Returns the token amount for a given market. The spot market precision is based on the token mint decimals.
     * Positive if it is a deposit, negative if it is a borrow.
     *
     * @param marketIndex
     */
    getTokenAmount(marketIndex) {
        const spotPosition = this.getSpotPosition(marketIndex);
        if (spotPosition === undefined) {
            return numericConstants_1.ZERO;
        }
        const spotMarket = this.driftClient.getSpotMarketAccount(marketIndex);
        return (0, _1.getSignedTokenAmount)((0, spotBalance_1.getTokenAmount)(spotPosition.scaledBalance, spotMarket, spotPosition.balanceType), spotPosition.balanceType);
    }
    getEmptyPosition(marketIndex) {
        return {
            baseAssetAmount: numericConstants_1.ZERO,
            remainderBaseAssetAmount: 0,
            lastCumulativeFundingRate: numericConstants_1.ZERO,
            marketIndex,
            quoteAssetAmount: numericConstants_1.ZERO,
            quoteEntryAmount: numericConstants_1.ZERO,
            quoteBreakEvenAmount: numericConstants_1.ZERO,
            openOrders: 0,
            openBids: numericConstants_1.ZERO,
            openAsks: numericConstants_1.ZERO,
            settledPnl: numericConstants_1.ZERO,
            lpShares: numericConstants_1.ZERO,
            lastBaseAssetAmountPerLp: numericConstants_1.ZERO,
            lastQuoteAssetAmountPerLp: numericConstants_1.ZERO,
            perLpBase: 0,
        };
    }
    getClonedPosition(position) {
        const clonedPosition = Object.assign({}, position);
        return clonedPosition;
    }
    getOrderForUserAccount(userAccount, orderId) {
        return userAccount.orders.find((order) => order.orderId === orderId);
    }
    /**
     * @param orderId
     * @returns Order
     */
    getOrder(orderId) {
        const userAccount = this.getUserAccount();
        return this.getOrderForUserAccount(userAccount, orderId);
    }
    getOrderAndSlot(orderId) {
        const userAccount = this.getUserAccountAndSlot();
        const order = this.getOrderForUserAccount(userAccount.data, orderId);
        return {
            data: order,
            slot: userAccount.slot,
        };
    }
    getOrderByUserIdForUserAccount(userAccount, userOrderId) {
        return userAccount.orders.find((order) => order.userOrderId === userOrderId);
    }
    /**
     * @param userOrderId
     * @returns Order
     */
    getOrderByUserOrderId(userOrderId) {
        const userAccount = this.getUserAccount();
        return this.getOrderByUserIdForUserAccount(userAccount, userOrderId);
    }
    getOrderByUserOrderIdAndSlot(userOrderId) {
        const userAccount = this.getUserAccountAndSlot();
        const order = this.getOrderByUserIdForUserAccount(userAccount.data, userOrderId);
        return {
            data: order,
            slot: userAccount.slot,
        };
    }
    getOpenOrdersForUserAccount(userAccount) {
        return userAccount === null || userAccount === void 0 ? void 0 : userAccount.orders.filter((order) => (0, types_1.isVariant)(order.status, 'open'));
    }
    getOpenOrders() {
        const userAccount = this.getUserAccount();
        return this.getOpenOrdersForUserAccount(userAccount);
    }
    getOpenOrdersAndSlot() {
        const userAccount = this.getUserAccountAndSlot();
        const openOrders = this.getOpenOrdersForUserAccount(userAccount.data);
        return {
            data: openOrders,
            slot: userAccount.slot,
        };
    }
    getUserAccountPublicKey() {
        return this.userAccountPublicKey;
    }
    async exists() {
        const userAccountRPCResponse = await this.driftClient.connection.getParsedAccountInfo(this.userAccountPublicKey);
        return userAccountRPCResponse.value !== null;
    }
    /**
     * calculates the total open bids/asks in a perp market (including lps)
     * @returns : open bids
     * @returns : open asks
     */
    getPerpBidAsks(marketIndex) {
        const position = this.getPerpPosition(marketIndex);
        const [lpOpenBids, lpOpenAsks] = this.getLPBidAsks(marketIndex);
        const totalOpenBids = lpOpenBids.add(position.openBids);
        const totalOpenAsks = lpOpenAsks.add(position.openAsks);
        return [totalOpenBids, totalOpenAsks];
    }
    /**
     * calculates the open bids and asks for an lp
     * optionally pass in lpShares to see what bid/asks a user *would* take on
     * @returns : lp open bids
     * @returns : lp open asks
     */
    getLPBidAsks(marketIndex, lpShares) {
        const position = this.getPerpPosition(marketIndex);
        const lpSharesToCalc = lpShares !== null && lpShares !== void 0 ? lpShares : position === null || position === void 0 ? void 0 : position.lpShares;
        if (!lpSharesToCalc || lpSharesToCalc.eq(numericConstants_1.ZERO)) {
            return [numericConstants_1.ZERO, numericConstants_1.ZERO];
        }
        const market = this.driftClient.getPerpMarketAccount(marketIndex);
        const [marketOpenBids, marketOpenAsks] = (0, amm_1.calculateMarketOpenBidAsk)(market.amm.baseAssetReserve, market.amm.minBaseAssetReserve, market.amm.maxBaseAssetReserve, market.amm.orderStepSize);
        const lpOpenBids = marketOpenBids.mul(lpSharesToCalc).div(market.amm.sqrtK);
        const lpOpenAsks = marketOpenAsks.mul(lpSharesToCalc).div(market.amm.sqrtK);
        return [lpOpenBids, lpOpenAsks];
    }
    /**
     * calculates the market position if the lp position was settled
     * @returns : the settled userPosition
     * @returns : the dust base asset amount (ie, < stepsize)
     * @returns : pnl from settle
     */
    getPerpPositionWithLPSettle(marketIndex, originalPosition, burnLpShares = false, includeRemainderInBaseAmount = false) {
        var _a;
        originalPosition =
            (_a = originalPosition !== null && originalPosition !== void 0 ? originalPosition : this.getPerpPosition(marketIndex)) !== null && _a !== void 0 ? _a : this.getEmptyPosition(marketIndex);
        if (originalPosition.lpShares.eq(numericConstants_1.ZERO)) {
            return [originalPosition, numericConstants_1.ZERO, numericConstants_1.ZERO];
        }
        const position = this.getClonedPosition(originalPosition);
        const market = this.driftClient.getPerpMarketAccount(position.marketIndex);
        if (market.amm.perLpBase != position.perLpBase) {
            // perLpBase = 1 => per 10 LP shares, perLpBase = -1 => per 0.1 LP shares
            const expoDiff = market.amm.perLpBase - position.perLpBase;
            const marketPerLpRebaseScalar = new _1.BN(10 ** Math.abs(expoDiff));
            if (expoDiff > 0) {
                position.lastBaseAssetAmountPerLp =
                    position.lastBaseAssetAmountPerLp.mul(marketPerLpRebaseScalar);
                position.lastQuoteAssetAmountPerLp =
                    position.lastQuoteAssetAmountPerLp.mul(marketPerLpRebaseScalar);
            }
            else {
                position.lastBaseAssetAmountPerLp =
                    position.lastBaseAssetAmountPerLp.div(marketPerLpRebaseScalar);
                position.lastQuoteAssetAmountPerLp =
                    position.lastQuoteAssetAmountPerLp.div(marketPerLpRebaseScalar);
            }
            position.perLpBase = position.perLpBase + expoDiff;
        }
        const nShares = position.lpShares;
        // incorp unsettled funding on pre settled position
        const quoteFundingPnl = (0, _1.calculatePositionFundingPNL)(market, position);
        let baseUnit = numericConstants_1.AMM_RESERVE_PRECISION;
        if (market.amm.perLpBase == position.perLpBase) {
            if (position.perLpBase >= 0 &&
                position.perLpBase <= numericConstants_1.AMM_RESERVE_PRECISION_EXP.toNumber()) {
                const marketPerLpRebase = new _1.BN(10 ** market.amm.perLpBase);
                baseUnit = baseUnit.mul(marketPerLpRebase);
            }
            else if (position.perLpBase < 0 &&
                position.perLpBase >= -numericConstants_1.AMM_RESERVE_PRECISION_EXP.toNumber()) {
                const marketPerLpRebase = new _1.BN(10 ** Math.abs(market.amm.perLpBase));
                baseUnit = baseUnit.div(marketPerLpRebase);
            }
            else {
                throw 'cannot calc';
            }
        }
        else {
            throw 'market.amm.perLpBase != position.perLpBase';
        }
        const deltaBaa = market.amm.baseAssetAmountPerLp
            .sub(position.lastBaseAssetAmountPerLp)
            .mul(nShares)
            .div(baseUnit);
        const deltaQaa = market.amm.quoteAssetAmountPerLp
            .sub(position.lastQuoteAssetAmountPerLp)
            .mul(nShares)
            .div(baseUnit);
        function sign(v) {
            return v.isNeg() ? new _1.BN(-1) : new _1.BN(1);
        }
        function standardize(amount, stepSize) {
            const remainder = amount.abs().mod(stepSize).mul(sign(amount));
            const standardizedAmount = amount.sub(remainder);
            return [standardizedAmount, remainder];
        }
        const [standardizedBaa, remainderBaa] = standardize(deltaBaa, market.amm.orderStepSize);
        position.remainderBaseAssetAmount += remainderBaa.toNumber();
        if (Math.abs(position.remainderBaseAssetAmount) >
            market.amm.orderStepSize.toNumber()) {
            const [newStandardizedBaa, newRemainderBaa] = standardize(new _1.BN(position.remainderBaseAssetAmount), market.amm.orderStepSize);
            position.baseAssetAmount =
                position.baseAssetAmount.add(newStandardizedBaa);
            position.remainderBaseAssetAmount = newRemainderBaa.toNumber();
        }
        let dustBaseAssetValue = numericConstants_1.ZERO;
        if (burnLpShares && position.remainderBaseAssetAmount != 0) {
            const oraclePriceData = this.driftClient.getOracleDataForPerpMarket(position.marketIndex);
            dustBaseAssetValue = new _1.BN(Math.abs(position.remainderBaseAssetAmount))
                .mul(oraclePriceData.price)
                .div(numericConstants_1.AMM_RESERVE_PRECISION)
                .add(numericConstants_1.ONE);
        }
        let updateType;
        if (position.baseAssetAmount.eq(numericConstants_1.ZERO)) {
            updateType = 'open';
        }
        else if (sign(position.baseAssetAmount).eq(sign(deltaBaa))) {
            updateType = 'increase';
        }
        else if (position.baseAssetAmount.abs().gt(deltaBaa.abs())) {
            updateType = 'reduce';
        }
        else if (position.baseAssetAmount.abs().eq(deltaBaa.abs())) {
            updateType = 'close';
        }
        else {
            updateType = 'flip';
        }
        let newQuoteEntry;
        let pnl;
        if (updateType == 'open' || updateType == 'increase') {
            newQuoteEntry = position.quoteEntryAmount.add(deltaQaa);
            pnl = numericConstants_1.ZERO;
        }
        else if (updateType == 'reduce' || updateType == 'close') {
            newQuoteEntry = position.quoteEntryAmount.sub(position.quoteEntryAmount
                .mul(deltaBaa.abs())
                .div(position.baseAssetAmount.abs()));
            pnl = position.quoteEntryAmount.sub(newQuoteEntry).add(deltaQaa);
        }
        else {
            newQuoteEntry = deltaQaa.sub(deltaQaa.mul(position.baseAssetAmount.abs()).div(deltaBaa.abs()));
            pnl = position.quoteEntryAmount.add(deltaQaa.sub(newQuoteEntry));
        }
        position.quoteEntryAmount = newQuoteEntry;
        position.baseAssetAmount = position.baseAssetAmount.add(standardizedBaa);
        position.quoteAssetAmount = position.quoteAssetAmount
            .add(deltaQaa)
            .add(quoteFundingPnl)
            .sub(dustBaseAssetValue);
        position.quoteBreakEvenAmount = position.quoteBreakEvenAmount
            .add(deltaQaa)
            .add(quoteFundingPnl)
            .sub(dustBaseAssetValue);
        // update open bids/asks
        const [marketOpenBids, marketOpenAsks] = (0, amm_1.calculateMarketOpenBidAsk)(market.amm.baseAssetReserve, market.amm.minBaseAssetReserve, market.amm.maxBaseAssetReserve, market.amm.orderStepSize);
        const lpOpenBids = marketOpenBids
            .mul(position.lpShares)
            .div(market.amm.sqrtK);
        const lpOpenAsks = marketOpenAsks
            .mul(position.lpShares)
            .div(market.amm.sqrtK);
        position.openBids = lpOpenBids.add(position.openBids);
        position.openAsks = lpOpenAsks.add(position.openAsks);
        // eliminate counting funding on settled position
        if (position.baseAssetAmount.gt(numericConstants_1.ZERO)) {
            position.lastCumulativeFundingRate = market.amm.cumulativeFundingRateLong;
        }
        else if (position.baseAssetAmount.lt(numericConstants_1.ZERO)) {
            position.lastCumulativeFundingRate =
                market.amm.cumulativeFundingRateShort;
        }
        else {
            position.lastCumulativeFundingRate = numericConstants_1.ZERO;
        }
        const remainderBeforeRemoval = new _1.BN(position.remainderBaseAssetAmount);
        if (includeRemainderInBaseAmount) {
            position.baseAssetAmount = position.baseAssetAmount.add(remainderBeforeRemoval);
            position.remainderBaseAssetAmount = 0;
        }
        return [position, remainderBeforeRemoval, pnl];
    }
    /**
     * calculates Buying Power = free collateral / initial margin ratio
     * @returns : Precision QUOTE_PRECISION
     */
    getPerpBuyingPower(marketIndex, collateralBuffer = numericConstants_1.ZERO) {
        const perpPosition = this.getPerpPositionWithLPSettle(marketIndex, undefined, true)[0];
        const perpMarket = this.driftClient.getPerpMarketAccount(marketIndex);
        const oraclePriceData = this.getOracleDataForPerpMarket(marketIndex);
        const worstCaseBaseAssetAmount = perpPosition
            ? (0, margin_1.calculateWorstCaseBaseAssetAmount)(perpPosition, perpMarket, oraclePriceData.price)
            : numericConstants_1.ZERO;
        const freeCollateral = this.getFreeCollateral().sub(collateralBuffer);
        return this.getPerpBuyingPowerFromFreeCollateralAndBaseAssetAmount(marketIndex, freeCollateral, worstCaseBaseAssetAmount);
    }
    getPerpBuyingPowerFromFreeCollateralAndBaseAssetAmount(marketIndex, freeCollateral, baseAssetAmount) {
        const marginRatio = (0, _1.calculateMarketMarginRatio)(this.driftClient.getPerpMarketAccount(marketIndex), baseAssetAmount, 'Initial', this.getUserAccount().maxMarginRatio);
        return freeCollateral.mul(numericConstants_1.MARGIN_PRECISION).div(new _1.BN(marginRatio));
    }
    /**
     * calculates Free Collateral = Total collateral - margin requirement
     * @returns : Precision QUOTE_PRECISION
     */
    getFreeCollateral(marginCategory = 'Initial') {
        const totalCollateral = this.getTotalCollateral(marginCategory, true);
        const marginRequirement = marginCategory === 'Initial'
            ? this.getInitialMarginRequirement()
            : this.getMaintenanceMarginRequirement();
        const freeCollateral = totalCollateral.sub(marginRequirement);
        return freeCollateral.gte(numericConstants_1.ZERO) ? freeCollateral : numericConstants_1.ZERO;
    }
    /**
     * @returns The margin requirement of a certain type (Initial or Maintenance) in USDC. : QUOTE_PRECISION
     */
    getMarginRequirement(marginCategory, liquidationBuffer, strict = false, includeOpenOrders = true) {
        return this.getTotalPerpPositionLiability(marginCategory, liquidationBuffer, includeOpenOrders, strict).add(this.getSpotMarketLiabilityValue(undefined, marginCategory, liquidationBuffer, includeOpenOrders, strict));
    }
    /**
     * @returns The initial margin requirement in USDC. : QUOTE_PRECISION
     */
    getInitialMarginRequirement() {
        return this.getMarginRequirement('Initial', undefined, true);
    }
    /**
     * @returns The maintenance margin requirement in USDC. : QUOTE_PRECISION
     */
    getMaintenanceMarginRequirement() {
        // if user being liq'd, can continue to be liq'd until total collateral above the margin requirement plus buffer
        let liquidationBuffer = undefined;
        if (this.isBeingLiquidated()) {
            liquidationBuffer = new _1.BN(this.driftClient.getStateAccount().liquidationMarginBufferRatio);
        }
        return this.getMarginRequirement('Maintenance', liquidationBuffer);
    }
    getActivePerpPositionsForUserAccount(userAccount) {
        return userAccount.perpPositions.filter((pos) => !pos.baseAssetAmount.eq(numericConstants_1.ZERO) ||
            !pos.quoteAssetAmount.eq(numericConstants_1.ZERO) ||
            !(pos.openOrders == 0) ||
            !pos.lpShares.eq(numericConstants_1.ZERO));
    }
    getActivePerpPositions() {
        const userAccount = this.getUserAccount();
        return this.getActivePerpPositionsForUserAccount(userAccount);
    }
    getActivePerpPositionsAndSlot() {
        const userAccount = this.getUserAccountAndSlot();
        const positions = this.getActivePerpPositionsForUserAccount(userAccount.data);
        return {
            data: positions,
            slot: userAccount.slot,
        };
    }
    getActiveSpotPositionsForUserAccount(userAccount) {
        return userAccount.spotPositions.filter((pos) => !(0, spotPosition_1.isSpotPositionAvailable)(pos));
    }
    getActiveSpotPositions() {
        const userAccount = this.getUserAccount();
        return this.getActiveSpotPositionsForUserAccount(userAccount);
    }
    getActiveSpotPositionsAndSlot() {
        const userAccount = this.getUserAccountAndSlot();
        const positions = this.getActiveSpotPositionsForUserAccount(userAccount.data);
        return {
            data: positions,
            slot: userAccount.slot,
        };
    }
    /**
     * calculates unrealized position price pnl
     * @returns : Precision QUOTE_PRECISION
     */
    getUnrealizedPNL(withFunding, marketIndex, withWeightMarginCategory, strict = false) {
        return this.getActivePerpPositions()
            .filter((pos) => marketIndex !== undefined ? pos.marketIndex === marketIndex : true)
            .reduce((unrealizedPnl, perpPosition) => {
            const market = this.driftClient.getPerpMarketAccount(perpPosition.marketIndex);
            const oraclePriceData = this.getOracleDataForPerpMarket(market.marketIndex);
            const quoteSpotMarket = this.driftClient.getSpotMarketAccount(market.quoteSpotMarketIndex);
            const quoteOraclePriceData = this.getOracleDataForSpotMarket(market.quoteSpotMarketIndex);
            if (perpPosition.lpShares.gt(numericConstants_1.ZERO)) {
                perpPosition = this.getPerpPositionWithLPSettle(perpPosition.marketIndex, undefined, !!withWeightMarginCategory)[0];
            }
            let positionUnrealizedPnl = (0, _1.calculatePositionPNL)(market, perpPosition, withFunding, oraclePriceData);
            let quotePrice;
            if (strict && positionUnrealizedPnl.gt(numericConstants_1.ZERO)) {
                quotePrice = _1.BN.min(quoteOraclePriceData.price, quoteSpotMarket.historicalOracleData.lastOraclePriceTwap5Min);
            }
            else if (strict && positionUnrealizedPnl.lt(numericConstants_1.ZERO)) {
                quotePrice = _1.BN.max(quoteOraclePriceData.price, quoteSpotMarket.historicalOracleData.lastOraclePriceTwap5Min);
            }
            else {
                quotePrice = quoteOraclePriceData.price;
            }
            positionUnrealizedPnl = positionUnrealizedPnl
                .mul(quotePrice)
                .div(numericConstants_1.PRICE_PRECISION);
            if (withWeightMarginCategory !== undefined) {
                if (positionUnrealizedPnl.gt(numericConstants_1.ZERO)) {
                    positionUnrealizedPnl = positionUnrealizedPnl
                        .mul((0, _1.calculateUnrealizedAssetWeight)(market, quoteSpotMarket, positionUnrealizedPnl, withWeightMarginCategory, oraclePriceData))
                        .div(new _1.BN(numericConstants_1.SPOT_MARKET_WEIGHT_PRECISION));
                }
            }
            return unrealizedPnl.add(positionUnrealizedPnl);
        }, numericConstants_1.ZERO);
    }
    /**
     * calculates unrealized funding payment pnl
     * @returns : Precision QUOTE_PRECISION
     */
    getUnrealizedFundingPNL(marketIndex) {
        return this.getUserAccount()
            .perpPositions.filter((pos) => marketIndex ? pos.marketIndex === marketIndex : true)
            .reduce((pnl, perpPosition) => {
            const market = this.driftClient.getPerpMarketAccount(perpPosition.marketIndex);
            return pnl.add((0, _1.calculatePositionFundingPNL)(market, perpPosition));
        }, numericConstants_1.ZERO);
    }
    getFuelBonus(now, includeSettled = true, includeUnsettled = true) {
        const userAccount = this.getUserAccount();
        const result = {
            insuranceFuel: numericConstants_1.ZERO,
            takerFuel: numericConstants_1.ZERO,
            makerFuel: numericConstants_1.ZERO,
            depositFuel: numericConstants_1.ZERO,
            borrowFuel: numericConstants_1.ZERO,
            positionFuel: numericConstants_1.ZERO,
        };
        if (includeSettled) {
            const userStats = this.driftClient
                .getUserStats()
                .getAccount();
            result.insuranceFuel = result.insuranceFuel.add(new _1.BN(userStats.fuelInsurance));
            result.takerFuel = result.takerFuel.add(new _1.BN(userStats.fuelTaker));
            result.makerFuel = result.makerFuel.add(new _1.BN(userStats.fuelMaker));
            result.depositFuel = result.depositFuel.add(new _1.BN(userStats.fuelDeposits));
            result.borrowFuel = result.borrowFuel.add(new _1.BN(userStats.fuelBorrows));
            result.positionFuel = result.positionFuel.add(new _1.BN(userStats.fuelPositions));
        }
        if (includeUnsettled) {
            const fuelBonusNumerator = _1.BN.max(now.sub(_1.BN.max(new _1.BN(userAccount.lastFuelBonusUpdateTs), numericConstants_1.FUEL_START_TS)), numericConstants_1.ZERO);
            if (fuelBonusNumerator.gt(numericConstants_1.ZERO)) {
                for (const spotPosition of this.getActiveSpotPositions()) {
                    const spotMarketAccount = this.driftClient.getSpotMarketAccount(spotPosition.marketIndex);
                    const tokenAmount = this.getTokenAmount(spotPosition.marketIndex);
                    const oraclePriceData = this.getOracleDataForSpotMarket(spotPosition.marketIndex);
                    const twap5min = (0, oracles_1.calculateLiveOracleTwap)(spotMarketAccount.historicalOracleData, oraclePriceData, now, numericConstants_1.FIVE_MINUTE // 5MIN
                    );
                    const strictOraclePrice = new strictOraclePrice_1.StrictOraclePrice(oraclePriceData.price, twap5min);
                    const signedTokenValue = (0, _1.getStrictTokenValue)(tokenAmount, spotMarketAccount.decimals, strictOraclePrice);
                    if (signedTokenValue.gt(numericConstants_1.ZERO)) {
                        result.depositFuel = result.depositFuel.add((0, fuel_1.calculateSpotFuelBonus)(spotMarketAccount, signedTokenValue, fuelBonusNumerator));
                    }
                    else {
                        result.borrowFuel = result.borrowFuel.add((0, fuel_1.calculateSpotFuelBonus)(spotMarketAccount, signedTokenValue, fuelBonusNumerator));
                    }
                }
                for (const perpPosition of this.getActivePerpPositions()) {
                    const oraclePriceData = this.getOracleDataForPerpMarket(perpPosition.marketIndex);
                    const perpMarketAccount = this.driftClient.getPerpMarketAccount(perpPosition.marketIndex);
                    const baseAssetValue = this.getPerpPositionValue(perpPosition.marketIndex, oraclePriceData, false);
                    result.positionFuel = result.positionFuel.add((0, fuel_1.calculatePerpFuelBonus)(perpMarketAccount, baseAssetValue, fuelBonusNumerator));
                }
            }
            const userStats = this.driftClient
                .getUserStats()
                .getAccount();
            // todo: get real time ifStakedGovTokenAmount using ifStakeAccount
            if (userStats.ifStakedGovTokenAmount.gt(numericConstants_1.ZERO)) {
                const spotMarketAccount = this.driftClient.getSpotMarketAccount(numericConstants_1.GOV_SPOT_MARKET_INDEX);
                const fuelBonusNumeratorUserStats = _1.BN.max(now.sub(_1.BN.max(new _1.BN(userStats.lastFuelIfBonusUpdateTs), numericConstants_1.FUEL_START_TS)), numericConstants_1.ZERO);
                result.insuranceFuel = result.insuranceFuel.add((0, fuel_1.calculateInsuranceFuelBonus)(spotMarketAccount, userStats.ifStakedGovTokenAmount, fuelBonusNumeratorUserStats));
            }
            if (userStats.ifStakedQuoteAssetAmount.gt(numericConstants_1.ZERO)) {
                const spotMarketAccount = this.driftClient.getSpotMarketAccount(numericConstants_1.QUOTE_SPOT_MARKET_INDEX);
                const fuelBonusNumeratorUserStats = _1.BN.max(now.sub(_1.BN.max(new _1.BN(userStats.lastFuelIfBonusUpdateTs), numericConstants_1.FUEL_START_TS)), numericConstants_1.ZERO);
                result.insuranceFuel = result.insuranceFuel.add((0, fuel_1.calculateInsuranceFuelBonus)(spotMarketAccount, userStats.ifStakedQuoteAssetAmount, fuelBonusNumeratorUserStats));
            }
        }
        return result;
    }
    getSpotMarketAssetAndLiabilityValue(marketIndex, marginCategory, liquidationBuffer, includeOpenOrders, strict = false, now) {
        now = now || new _1.BN(new Date().getTime() / 1000);
        let netQuoteValue = numericConstants_1.ZERO;
        let totalAssetValue = numericConstants_1.ZERO;
        let totalLiabilityValue = numericConstants_1.ZERO;
        for (const spotPosition of this.getUserAccount().spotPositions) {
            const countForBase = marketIndex === undefined || spotPosition.marketIndex === marketIndex;
            const countForQuote = marketIndex === undefined ||
                marketIndex === numericConstants_1.QUOTE_SPOT_MARKET_INDEX ||
                (includeOpenOrders && spotPosition.openOrders !== 0);
            if ((0, spotPosition_1.isSpotPositionAvailable)(spotPosition) ||
                (!countForBase && !countForQuote)) {
                continue;
            }
            const spotMarketAccount = this.driftClient.getSpotMarketAccount(spotPosition.marketIndex);
            const oraclePriceData = this.getOracleDataForSpotMarket(spotPosition.marketIndex);
            let twap5min;
            if (strict) {
                twap5min = (0, oracles_1.calculateLiveOracleTwap)(spotMarketAccount.historicalOracleData, oraclePriceData, now, numericConstants_1.FIVE_MINUTE // 5MIN
                );
            }
            const strictOraclePrice = new strictOraclePrice_1.StrictOraclePrice(oraclePriceData.price, twap5min);
            if (spotPosition.marketIndex === numericConstants_1.QUOTE_SPOT_MARKET_INDEX &&
                countForQuote) {
                const tokenAmount = (0, _1.getSignedTokenAmount)((0, spotBalance_1.getTokenAmount)(spotPosition.scaledBalance, spotMarketAccount, spotPosition.balanceType), spotPosition.balanceType);
                if ((0, types_1.isVariant)(spotPosition.balanceType, 'borrow')) {
                    const weightedTokenValue = this.getSpotLiabilityValue(tokenAmount, strictOraclePrice, spotMarketAccount, marginCategory, liquidationBuffer).abs();
                    netQuoteValue = netQuoteValue.sub(weightedTokenValue);
                }
                else {
                    const weightedTokenValue = this.getSpotAssetValue(tokenAmount, strictOraclePrice, spotMarketAccount, marginCategory);
                    netQuoteValue = netQuoteValue.add(weightedTokenValue);
                }
                continue;
            }
            if (!includeOpenOrders && countForBase) {
                if ((0, types_1.isVariant)(spotPosition.balanceType, 'borrow')) {
                    const tokenAmount = (0, _1.getSignedTokenAmount)((0, spotBalance_1.getTokenAmount)(spotPosition.scaledBalance, spotMarketAccount, spotPosition.balanceType), _1.SpotBalanceType.BORROW);
                    const liabilityValue = this.getSpotLiabilityValue(tokenAmount, strictOraclePrice, spotMarketAccount, marginCategory, liquidationBuffer).abs();
                    totalLiabilityValue = totalLiabilityValue.add(liabilityValue);
                    continue;
                }
                else {
                    const tokenAmount = (0, spotBalance_1.getTokenAmount)(spotPosition.scaledBalance, spotMarketAccount, spotPosition.balanceType);
                    const assetValue = this.getSpotAssetValue(tokenAmount, strictOraclePrice, spotMarketAccount, marginCategory);
                    totalAssetValue = totalAssetValue.add(assetValue);
                    continue;
                }
            }
            const { tokenAmount: worstCaseTokenAmount, ordersValue: worstCaseQuoteTokenAmount, } = (0, spotPosition_1.getWorstCaseTokenAmounts)(spotPosition, spotMarketAccount, strictOraclePrice, marginCategory, this.getUserAccount().maxMarginRatio);
            if (worstCaseTokenAmount.gt(numericConstants_1.ZERO) && countForBase) {
                const baseAssetValue = this.getSpotAssetValue(worstCaseTokenAmount, strictOraclePrice, spotMarketAccount, marginCategory);
                totalAssetValue = totalAssetValue.add(baseAssetValue);
            }
            if (worstCaseTokenAmount.lt(numericConstants_1.ZERO) && countForBase) {
                const baseLiabilityValue = this.getSpotLiabilityValue(worstCaseTokenAmount, strictOraclePrice, spotMarketAccount, marginCategory, liquidationBuffer).abs();
                totalLiabilityValue = totalLiabilityValue.add(baseLiabilityValue);
            }
            if (worstCaseQuoteTokenAmount.gt(numericConstants_1.ZERO) && countForQuote) {
                netQuoteValue = netQuoteValue.add(worstCaseQuoteTokenAmount);
            }
            if (worstCaseQuoteTokenAmount.lt(numericConstants_1.ZERO) && countForQuote) {
                let weight = numericConstants_1.SPOT_MARKET_WEIGHT_PRECISION;
                if (marginCategory === 'Initial') {
                    weight = _1.BN.max(weight, new _1.BN(this.getUserAccount().maxMarginRatio));
                }
                const weightedTokenValue = worstCaseQuoteTokenAmount
                    .abs()
                    .mul(weight)
                    .div(numericConstants_1.SPOT_MARKET_WEIGHT_PRECISION);
                netQuoteValue = netQuoteValue.sub(weightedTokenValue);
            }
            totalLiabilityValue = totalLiabilityValue.add(new _1.BN(spotPosition.openOrders).mul(numericConstants_1.OPEN_ORDER_MARGIN_REQUIREMENT));
        }
        if (marketIndex === undefined || marketIndex === numericConstants_1.QUOTE_SPOT_MARKET_INDEX) {
            if (netQuoteValue.gt(numericConstants_1.ZERO)) {
                totalAssetValue = totalAssetValue.add(netQuoteValue);
            }
            else {
                totalLiabilityValue = totalLiabilityValue.add(netQuoteValue.abs());
            }
        }
        return { totalAssetValue, totalLiabilityValue };
    }
    getSpotMarketLiabilityValue(marketIndex, marginCategory, liquidationBuffer, includeOpenOrders, strict = false, now) {
        const { totalLiabilityValue } = this.getSpotMarketAssetAndLiabilityValue(marketIndex, marginCategory, liquidationBuffer, includeOpenOrders, strict, now);
        return totalLiabilityValue;
    }
    getSpotLiabilityValue(tokenAmount, strictOraclePrice, spotMarketAccount, marginCategory, liquidationBuffer) {
        let liabilityValue = (0, _1.getStrictTokenValue)(tokenAmount, spotMarketAccount.decimals, strictOraclePrice);
        if (marginCategory !== undefined) {
            let weight = (0, spotBalance_1.calculateLiabilityWeight)(tokenAmount, spotMarketAccount, marginCategory);
            if (marginCategory === 'Initial' &&
                spotMarketAccount.marketIndex !== numericConstants_1.QUOTE_SPOT_MARKET_INDEX) {
                weight = _1.BN.max(weight, numericConstants_1.SPOT_MARKET_WEIGHT_PRECISION.addn(this.getUserAccount().maxMarginRatio));
            }
            if (liquidationBuffer !== undefined) {
                weight = weight.add(liquidationBuffer);
            }
            liabilityValue = liabilityValue
                .mul(weight)
                .div(numericConstants_1.SPOT_MARKET_WEIGHT_PRECISION);
        }
        return liabilityValue;
    }
    getSpotMarketAssetValue(marketIndex, marginCategory, includeOpenOrders, strict = false, now) {
        const { totalAssetValue } = this.getSpotMarketAssetAndLiabilityValue(marketIndex, marginCategory, undefined, includeOpenOrders, strict, now);
        return totalAssetValue;
    }
    getSpotAssetValue(tokenAmount, strictOraclePrice, spotMarketAccount, marginCategory) {
        let assetValue = (0, _1.getStrictTokenValue)(tokenAmount, spotMarketAccount.decimals, strictOraclePrice);
        if (marginCategory !== undefined) {
            let weight = (0, spotBalance_1.calculateAssetWeight)(tokenAmount, strictOraclePrice.current, spotMarketAccount, marginCategory);
            if (marginCategory === 'Initial' &&
                spotMarketAccount.marketIndex !== numericConstants_1.QUOTE_SPOT_MARKET_INDEX) {
                const userCustomAssetWeight = _1.BN.max(numericConstants_1.ZERO, numericConstants_1.SPOT_MARKET_WEIGHT_PRECISION.subn(this.getUserAccount().maxMarginRatio));
                weight = _1.BN.min(weight, userCustomAssetWeight);
            }
            assetValue = assetValue.mul(weight).div(numericConstants_1.SPOT_MARKET_WEIGHT_PRECISION);
        }
        return assetValue;
    }
    getSpotPositionValue(marketIndex, marginCategory, includeOpenOrders, strict = false, now) {
        const { totalAssetValue, totalLiabilityValue } = this.getSpotMarketAssetAndLiabilityValue(marketIndex, marginCategory, undefined, includeOpenOrders, strict, now);
        return totalAssetValue.sub(totalLiabilityValue);
    }
    getNetSpotMarketValue(withWeightMarginCategory) {
        const { totalAssetValue, totalLiabilityValue } = this.getSpotMarketAssetAndLiabilityValue(undefined, withWeightMarginCategory);
        return totalAssetValue.sub(totalLiabilityValue);
    }
    /**
     * calculates TotalCollateral: collateral + unrealized pnl
     * @returns : Precision QUOTE_PRECISION
     */
    getTotalCollateral(marginCategory = 'Initial', strict = false) {
        return this.getSpotMarketAssetValue(undefined, marginCategory, true, strict).add(this.getUnrealizedPNL(true, undefined, marginCategory, strict));
    }
    /**
     * calculates User Health by comparing total collateral and maint. margin requirement
     * @returns : number (value from [0, 100])
     */
    getHealth() {
        if (this.isBeingLiquidated()) {
            return 0;
        }
        const totalCollateral = this.getTotalCollateral('Maintenance');
        const maintenanceMarginReq = this.getMaintenanceMarginRequirement();
        let health;
        if (maintenanceMarginReq.eq(numericConstants_1.ZERO) && totalCollateral.gte(numericConstants_1.ZERO)) {
            health = 100;
        }
        else if (totalCollateral.lte(numericConstants_1.ZERO)) {
            health = 0;
        }
        else {
            health = Math.round(Math.min(100, Math.max(0, (1 - maintenanceMarginReq.toNumber() / totalCollateral.toNumber()) *
                100)));
        }
        return health;
    }
    calculateWeightedPerpPositionLiability(perpPosition, marginCategory, liquidationBuffer, includeOpenOrders, strict = false) {
        const market = this.driftClient.getPerpMarketAccount(perpPosition.marketIndex);
        if (perpPosition.lpShares.gt(numericConstants_1.ZERO)) {
            // is an lp, clone so we dont mutate the position
            perpPosition = this.getPerpPositionWithLPSettle(market.marketIndex, this.getClonedPosition(perpPosition), !!marginCategory)[0];
        }
        let valuationPrice = this.getOracleDataForPerpMarket(market.marketIndex).price;
        if ((0, types_1.isVariant)(market.status, 'settlement')) {
            valuationPrice = market.expiryPrice;
        }
        let baseAssetAmount;
        let liabilityValue;
        if (includeOpenOrders) {
            const { worstCaseBaseAssetAmount, worstCaseLiabilityValue } = (0, _1.calculateWorstCasePerpLiabilityValue)(perpPosition, market, valuationPrice);
            baseAssetAmount = worstCaseBaseAssetAmount;
            liabilityValue = worstCaseLiabilityValue;
        }
        else {
            baseAssetAmount = perpPosition.baseAssetAmount;
            liabilityValue = (0, _1.calculatePerpLiabilityValue)(baseAssetAmount, valuationPrice, (0, types_1.isVariant)(market.contractType, 'prediction'));
        }
        if (marginCategory) {
            let marginRatio = new _1.BN((0, _1.calculateMarketMarginRatio)(market, baseAssetAmount.abs(), marginCategory, this.getUserAccount().maxMarginRatio));
            if (liquidationBuffer !== undefined) {
                marginRatio = marginRatio.add(liquidationBuffer);
            }
            if ((0, types_1.isVariant)(market.status, 'settlement')) {
                marginRatio = numericConstants_1.ZERO;
            }
            const quoteSpotMarket = this.driftClient.getSpotMarketAccount(market.quoteSpotMarketIndex);
            const quoteOraclePriceData = this.driftClient.getOracleDataForSpotMarket(numericConstants_1.QUOTE_SPOT_MARKET_INDEX);
            let quotePrice;
            if (strict) {
                quotePrice = _1.BN.max(quoteOraclePriceData.price, quoteSpotMarket.historicalOracleData.lastOraclePriceTwap5Min);
            }
            else {
                quotePrice = quoteOraclePriceData.price;
            }
            liabilityValue = liabilityValue
                .mul(quotePrice)
                .div(numericConstants_1.PRICE_PRECISION)
                .mul(marginRatio)
                .div(numericConstants_1.MARGIN_PRECISION);
            if (includeOpenOrders) {
                liabilityValue = liabilityValue.add(new _1.BN(perpPosition.openOrders).mul(numericConstants_1.OPEN_ORDER_MARGIN_REQUIREMENT));
                if (perpPosition.lpShares.gt(numericConstants_1.ZERO)) {
                    liabilityValue = liabilityValue.add(_1.BN.max(numericConstants_1.QUOTE_PRECISION, valuationPrice
                        .mul(market.amm.orderStepSize)
                        .mul(numericConstants_1.QUOTE_PRECISION)
                        .div(numericConstants_1.AMM_RESERVE_PRECISION)
                        .div(numericConstants_1.PRICE_PRECISION)));
                }
            }
        }
        return liabilityValue;
    }
    /**
     * calculates position value of a single perp market in margin system
     * @returns : Precision QUOTE_PRECISION
     */
    getPerpMarketLiabilityValue(marketIndex, marginCategory, liquidationBuffer, includeOpenOrders, strict = false) {
        const perpPosition = this.getPerpPosition(marketIndex);
        return this.calculateWeightedPerpPositionLiability(perpPosition, marginCategory, liquidationBuffer, includeOpenOrders, strict);
    }
    /**
     * calculates sum of position value across all positions in margin system
     * @returns : Precision QUOTE_PRECISION
     */
    getTotalPerpPositionLiability(marginCategory, liquidationBuffer, includeOpenOrders, strict = false) {
        return this.getActivePerpPositions().reduce((totalPerpValue, perpPosition) => {
            const baseAssetValue = this.calculateWeightedPerpPositionLiability(perpPosition, marginCategory, liquidationBuffer, includeOpenOrders, strict);
            return totalPerpValue.add(baseAssetValue);
        }, numericConstants_1.ZERO);
    }
    /**
     * calculates position value based on oracle
     * @returns : Precision QUOTE_PRECISION
     */
    getPerpPositionValue(marketIndex, oraclePriceData, includeOpenOrders = false) {
        const userPosition = this.getPerpPositionWithLPSettle(marketIndex, undefined, false, true)[0] || this.getEmptyPosition(marketIndex);
        const market = this.driftClient.getPerpMarketAccount(userPosition.marketIndex);
        return (0, margin_1.calculateBaseAssetValueWithOracle)(market, userPosition, oraclePriceData, includeOpenOrders);
    }
    /**
     * calculates position liabiltiy value in margin system
     * @returns : Precision QUOTE_PRECISION
     */
    getPerpLiabilityValue(marketIndex, oraclePriceData, includeOpenOrders = false) {
        const userPosition = this.getPerpPositionWithLPSettle(marketIndex, undefined, false, true)[0] || this.getEmptyPosition(marketIndex);
        const market = this.driftClient.getPerpMarketAccount(userPosition.marketIndex);
        if (includeOpenOrders) {
            return (0, _1.calculateWorstCasePerpLiabilityValue)(userPosition, market, oraclePriceData.price).worstCaseLiabilityValue;
        }
        else {
            return (0, _1.calculatePerpLiabilityValue)(userPosition.baseAssetAmount, oraclePriceData.price, (0, types_1.isVariant)(market.contractType, 'prediction'));
        }
    }
    getPositionSide(currentPosition) {
        if (currentPosition.baseAssetAmount.gt(numericConstants_1.ZERO)) {
            return _1.PositionDirection.LONG;
        }
        else if (currentPosition.baseAssetAmount.lt(numericConstants_1.ZERO)) {
            return _1.PositionDirection.SHORT;
        }
        else {
            return undefined;
        }
    }
    /**
     * calculates average exit price (optionally for closing up to 100% of position)
     * @returns : Precision PRICE_PRECISION
     */
    getPositionEstimatedExitPriceAndPnl(position, amountToClose, useAMMClose = false) {
        const market = this.driftClient.getPerpMarketAccount(position.marketIndex);
        const entryPrice = (0, position_1.calculateEntryPrice)(position);
        const oraclePriceData = this.getOracleDataForPerpMarket(position.marketIndex);
        if (amountToClose) {
            if (amountToClose.eq(numericConstants_1.ZERO)) {
                return [(0, _1.calculateReservePrice)(market, oraclePriceData), numericConstants_1.ZERO];
            }
            position = {
                baseAssetAmount: amountToClose,
                lastCumulativeFundingRate: position.lastCumulativeFundingRate,
                marketIndex: position.marketIndex,
                quoteAssetAmount: position.quoteAssetAmount,
            };
        }
        let baseAssetValue;
        if (useAMMClose) {
            baseAssetValue = (0, _1.calculateBaseAssetValue)(market, position, oraclePriceData);
        }
        else {
            baseAssetValue = (0, margin_1.calculateBaseAssetValueWithOracle)(market, position, oraclePriceData);
        }
        if (position.baseAssetAmount.eq(numericConstants_1.ZERO)) {
            return [numericConstants_1.ZERO, numericConstants_1.ZERO];
        }
        const exitPrice = baseAssetValue
            .mul(numericConstants_1.AMM_TO_QUOTE_PRECISION_RATIO)
            .mul(numericConstants_1.PRICE_PRECISION)
            .div(position.baseAssetAmount.abs());
        const pnlPerBase = exitPrice.sub(entryPrice);
        const pnl = pnlPerBase
            .mul(position.baseAssetAmount)
            .div(numericConstants_1.PRICE_PRECISION)
            .div(numericConstants_1.AMM_TO_QUOTE_PRECISION_RATIO);
        return [exitPrice, pnl];
    }
    /**
     * calculates current user leverage which is (total liability size) / (net asset value)
     * @returns : Precision TEN_THOUSAND
     */
    getLeverage(includeOpenOrders = true) {
        return this.calculateLeverageFromComponents(this.getLeverageComponents(includeOpenOrders));
    }
    calculateLeverageFromComponents({ perpLiabilityValue, perpPnl, spotAssetValue, spotLiabilityValue, }) {
        const totalLiabilityValue = perpLiabilityValue.add(spotLiabilityValue);
        const totalAssetValue = spotAssetValue.add(perpPnl);
        const netAssetValue = totalAssetValue.sub(spotLiabilityValue);
        if (netAssetValue.eq(numericConstants_1.ZERO)) {
            return numericConstants_1.ZERO;
        }
        return totalLiabilityValue.mul(numericConstants_1.TEN_THOUSAND).div(netAssetValue);
    }
    getLeverageComponents(includeOpenOrders = true, marginCategory = undefined) {
        const perpLiability = this.getTotalPerpPositionLiability(marginCategory, undefined, includeOpenOrders);
        const perpPnl = this.getUnrealizedPNL(true, undefined, marginCategory);
        const { totalAssetValue: spotAssetValue, totalLiabilityValue: spotLiabilityValue, } = this.getSpotMarketAssetAndLiabilityValue(undefined, marginCategory, undefined, includeOpenOrders);
        return {
            perpLiabilityValue: perpLiability,
            perpPnl,
            spotAssetValue,
            spotLiabilityValue,
        };
    }
    isDustDepositPosition(spotMarketAccount) {
        const marketIndex = spotMarketAccount.marketIndex;
        const spotPosition = this.getSpotPosition(spotMarketAccount.marketIndex);
        if ((0, spotPosition_1.isSpotPositionAvailable)(spotPosition)) {
            return false;
        }
        const depositAmount = this.getTokenAmount(spotMarketAccount.marketIndex);
        if (depositAmount.lte(numericConstants_1.ZERO)) {
            return false;
        }
        const oraclePriceData = this.getOracleDataForSpotMarket(marketIndex);
        const strictOraclePrice = new strictOraclePrice_1.StrictOraclePrice(oraclePriceData.price, oraclePriceData.twap);
        const balanceValue = this.getSpotAssetValue(depositAmount, strictOraclePrice, spotMarketAccount);
        if (balanceValue.lt(numericConstants_1.DUST_POSITION_SIZE)) {
            return true;
        }
        return false;
    }
    getSpotMarketAccountsWithDustPosition() {
        const spotMarketAccounts = this.driftClient.getSpotMarketAccounts();
        const dustPositionAccounts = [];
        for (const spotMarketAccount of spotMarketAccounts) {
            const isDust = this.isDustDepositPosition(spotMarketAccount);
            if (isDust) {
                dustPositionAccounts.push(spotMarketAccount);
            }
        }
        return dustPositionAccounts;
    }
    getTotalLiabilityValue(marginCategory) {
        return this.getTotalPerpPositionLiability(marginCategory, undefined, true).add(this.getSpotMarketLiabilityValue(undefined, marginCategory, undefined, true));
    }
    getTotalAssetValue(marginCategory) {
        return this.getSpotMarketAssetValue(undefined, marginCategory, true).add(this.getUnrealizedPNL(true, undefined, marginCategory));
    }
    getNetUsdValue() {
        const netSpotValue = this.getNetSpotMarketValue();
        const unrealizedPnl = this.getUnrealizedPNL(true, undefined, undefined);
        return netSpotValue.add(unrealizedPnl);
    }
    /**
     * Calculates the all time P&L of the user.
     *
     * Net withdraws + Net spot market value + Net unrealized P&L -
     */
    getTotalAllTimePnl() {
        const netUsdValue = this.getNetUsdValue();
        const totalDeposits = this.getUserAccount().totalDeposits;
        const totalWithdraws = this.getUserAccount().totalWithdraws;
        const totalPnl = netUsdValue.add(totalWithdraws).sub(totalDeposits);
        return totalPnl;
    }
    /**
     * calculates max allowable leverage exceeding hitting requirement category
     * for large sizes where imf factor activates, result is a lower bound
     * @param marginCategory {Initial, Maintenance}
     * @param isLp if calculating max leveraging for adding lp, need to add buffer
     * @returns : Precision TEN_THOUSAND
     */
    getMaxLeverageForPerp(perpMarketIndex, marginCategory = 'Initial', isLp = false) {
        const market = this.driftClient.getPerpMarketAccount(perpMarketIndex);
        const marketPrice = this.driftClient.getOracleDataForPerpMarket(perpMarketIndex).price;
        const { perpLiabilityValue, perpPnl, spotAssetValue, spotLiabilityValue } = this.getLeverageComponents();
        const totalAssetValue = spotAssetValue.add(perpPnl);
        const netAssetValue = totalAssetValue.sub(spotLiabilityValue);
        if (netAssetValue.eq(numericConstants_1.ZERO)) {
            return numericConstants_1.ZERO;
        }
        const totalLiabilityValue = perpLiabilityValue.add(spotLiabilityValue);
        const lpBuffer = isLp
            ? marketPrice.mul(market.amm.orderStepSize).div(numericConstants_1.AMM_RESERVE_PRECISION)
            : numericConstants_1.ZERO;
        const freeCollateral = this.getFreeCollateral().sub(lpBuffer);
        let rawMarginRatio;
        switch (marginCategory) {
            case 'Initial':
                rawMarginRatio = Math.max(market.marginRatioInitial, this.getUserAccount().maxMarginRatio);
                break;
            case 'Maintenance':
                rawMarginRatio = market.marginRatioMaintenance;
                break;
            default:
                rawMarginRatio = market.marginRatioInitial;
                break;
        }
        // absolute max fesible size (upper bound)
        const maxSize = _1.BN.max(numericConstants_1.ZERO, freeCollateral
            .mul(numericConstants_1.MARGIN_PRECISION)
            .div(new _1.BN(rawMarginRatio))
            .mul(numericConstants_1.PRICE_PRECISION)
            .div(marketPrice));
        // margin ratio incorporting upper bound on size
        let marginRatio = (0, _1.calculateMarketMarginRatio)(market, maxSize, marginCategory, this.getUserAccount().maxMarginRatio);
        // use more fesible size since imf factor activated
        let attempts = 0;
        while (marginRatio > rawMarginRatio + 1e-4 && attempts < 10) {
            // more fesible size (upper bound)
            const targetSize = _1.BN.max(numericConstants_1.ZERO, freeCollateral
                .mul(numericConstants_1.MARGIN_PRECISION)
                .div(new _1.BN(marginRatio))
                .mul(numericConstants_1.PRICE_PRECISION)
                .div(marketPrice));
            // margin ratio incorporting more fesible target size
            marginRatio = (0, _1.calculateMarketMarginRatio)(market, targetSize, marginCategory, this.getUserAccount().maxMarginRatio);
            attempts += 1;
        }
        // how much more liabilities can be opened w remaining free collateral
        const additionalLiabilities = freeCollateral
            .mul(numericConstants_1.MARGIN_PRECISION)
            .div(new _1.BN(marginRatio));
        return totalLiabilityValue
            .add(additionalLiabilities)
            .mul(numericConstants_1.TEN_THOUSAND)
            .div(netAssetValue);
    }
    /**
     * calculates max allowable leverage exceeding hitting requirement category
     * @param spotMarketIndex
     * @param direction
     * @returns : Precision TEN_THOUSAND
     */
    getMaxLeverageForSpot(spotMarketIndex, direction) {
        const { perpLiabilityValue, perpPnl, spotAssetValue, spotLiabilityValue } = this.getLeverageComponents();
        const totalLiabilityValue = perpLiabilityValue.add(spotLiabilityValue);
        const totalAssetValue = spotAssetValue.add(perpPnl);
        const netAssetValue = totalAssetValue.sub(spotLiabilityValue);
        if (netAssetValue.eq(numericConstants_1.ZERO)) {
            return numericConstants_1.ZERO;
        }
        const currentQuoteAssetValue = this.getSpotMarketAssetValue(numericConstants_1.QUOTE_SPOT_MARKET_INDEX);
        const currentQuoteLiabilityValue = this.getSpotMarketLiabilityValue(numericConstants_1.QUOTE_SPOT_MARKET_INDEX);
        const currentQuoteValue = currentQuoteAssetValue.sub(currentQuoteLiabilityValue);
        const currentSpotMarketAssetValue = this.getSpotMarketAssetValue(spotMarketIndex);
        const currentSpotMarketLiabilityValue = this.getSpotMarketLiabilityValue(spotMarketIndex);
        const currentSpotMarketNetValue = currentSpotMarketAssetValue.sub(currentSpotMarketLiabilityValue);
        const tradeQuoteAmount = this.getMaxTradeSizeUSDCForSpot(spotMarketIndex, direction, currentQuoteAssetValue, currentSpotMarketNetValue);
        let assetValueToAdd = numericConstants_1.ZERO;
        let liabilityValueToAdd = numericConstants_1.ZERO;
        const newQuoteNetValue = (0, types_1.isVariant)(direction, 'short')
            ? currentQuoteValue.add(tradeQuoteAmount)
            : currentQuoteValue.sub(tradeQuoteAmount);
        const newQuoteAssetValue = _1.BN.max(newQuoteNetValue, numericConstants_1.ZERO);
        const newQuoteLiabilityValue = _1.BN.min(newQuoteNetValue, numericConstants_1.ZERO).abs();
        assetValueToAdd = assetValueToAdd.add(newQuoteAssetValue.sub(currentQuoteAssetValue));
        liabilityValueToAdd = liabilityValueToAdd.add(newQuoteLiabilityValue.sub(currentQuoteLiabilityValue));
        const newSpotMarketNetValue = (0, types_1.isVariant)(direction, 'long')
            ? currentSpotMarketNetValue.add(tradeQuoteAmount)
            : currentSpotMarketNetValue.sub(tradeQuoteAmount);
        const newSpotMarketAssetValue = _1.BN.max(newSpotMarketNetValue, numericConstants_1.ZERO);
        const newSpotMarketLiabilityValue = _1.BN.min(newSpotMarketNetValue, numericConstants_1.ZERO).abs();
        assetValueToAdd = assetValueToAdd.add(newSpotMarketAssetValue.sub(currentSpotMarketAssetValue));
        liabilityValueToAdd = liabilityValueToAdd.add(newSpotMarketLiabilityValue.sub(currentSpotMarketLiabilityValue));
        const finalTotalAssetValue = totalAssetValue.add(assetValueToAdd);
        const finalTotalSpotLiability = spotLiabilityValue.add(liabilityValueToAdd);
        const finalTotalLiabilityValue = totalLiabilityValue.add(liabilityValueToAdd);
        const finalNetAssetValue = finalTotalAssetValue.sub(finalTotalSpotLiability);
        return finalTotalLiabilityValue.mul(numericConstants_1.TEN_THOUSAND).div(finalNetAssetValue);
    }
    /**
     * calculates margin ratio: 1 / leverage
     * @returns : Precision TEN_THOUSAND
     */
    getMarginRatio() {
        const { perpLiabilityValue, perpPnl, spotAssetValue, spotLiabilityValue } = this.getLeverageComponents();
        const totalLiabilityValue = perpLiabilityValue.add(spotLiabilityValue);
        const totalAssetValue = spotAssetValue.add(perpPnl);
        if (totalLiabilityValue.eq(numericConstants_1.ZERO)) {
            return numericConstants_1.BN_MAX;
        }
        const netAssetValue = totalAssetValue.sub(spotLiabilityValue);
        return netAssetValue.mul(numericConstants_1.TEN_THOUSAND).div(totalLiabilityValue);
    }
    canBeLiquidated() {
        const totalCollateral = this.getTotalCollateral('Maintenance');
        const marginRequirement = this.getMaintenanceMarginRequirement();
        const canBeLiquidated = totalCollateral.lt(marginRequirement);
        return {
            canBeLiquidated,
            marginRequirement,
            totalCollateral,
        };
    }
    isBeingLiquidated() {
        return ((this.getUserAccount().status &
            (types_1.UserStatus.BEING_LIQUIDATED | types_1.UserStatus.BANKRUPT)) >
            0);
    }
    hasStatus(status) {
        return (this.getUserAccount().status & status) > 0;
    }
    isBankrupt() {
        return (this.getUserAccount().status & types_1.UserStatus.BANKRUPT) > 0;
    }
    /**
     * Checks if any user position cumulative funding differs from respective market cumulative funding
     * @returns
     */
    needsToSettleFundingPayment() {
        for (const userPosition of this.getUserAccount().perpPositions) {
            if (userPosition.baseAssetAmount.eq(numericConstants_1.ZERO)) {
                continue;
            }
            const market = this.driftClient.getPerpMarketAccount(userPosition.marketIndex);
            if (market.amm.cumulativeFundingRateLong.eq(userPosition.lastCumulativeFundingRate) ||
                market.amm.cumulativeFundingRateShort.eq(userPosition.lastCumulativeFundingRate)) {
                continue;
            }
            return true;
        }
        return false;
    }
    /**
     * Calculate the liquidation price of a spot position
     * @param marketIndex
     * @returns Precision : PRICE_PRECISION
     */
    spotLiquidationPrice(marketIndex, positionBaseSizeChange = numericConstants_1.ZERO) {
        const currentSpotPosition = this.getSpotPosition(marketIndex);
        if (!currentSpotPosition) {
            return new _1.BN(-1);
        }
        const totalCollateral = this.getTotalCollateral('Maintenance');
        const maintenanceMarginRequirement = this.getMaintenanceMarginRequirement();
        const freeCollateral = _1.BN.max(numericConstants_1.ZERO, totalCollateral.sub(maintenanceMarginRequirement));
        const market = this.driftClient.getSpotMarketAccount(marketIndex);
        let signedTokenAmount = (0, _1.getSignedTokenAmount)((0, spotBalance_1.getTokenAmount)(currentSpotPosition.scaledBalance, market, currentSpotPosition.balanceType), currentSpotPosition.balanceType);
        signedTokenAmount = signedTokenAmount.add(positionBaseSizeChange);
        if (signedTokenAmount.eq(numericConstants_1.ZERO)) {
            return new _1.BN(-1);
        }
        let freeCollateralDelta = this.calculateFreeCollateralDeltaForSpot(market, signedTokenAmount);
        const oracle = market.oracle;
        const perpMarketWithSameOracle = this.driftClient
            .getPerpMarketAccounts()
            .find((market) => market.amm.oracle.equals(oracle));
        const oraclePrice = this.driftClient.getOracleDataForSpotMarket(marketIndex).price;
        if (perpMarketWithSameOracle) {
            const perpPosition = this.getPerpPositionWithLPSettle(perpMarketWithSameOracle.marketIndex, undefined, true)[0];
            if (perpPosition) {
                const freeCollateralDeltaForPerp = this.calculateFreeCollateralDeltaForPerp(perpMarketWithSameOracle, perpPosition, numericConstants_1.ZERO, oraclePrice);
                freeCollateralDelta = freeCollateralDelta.add(freeCollateralDeltaForPerp || numericConstants_1.ZERO);
            }
        }
        if (freeCollateralDelta.eq(numericConstants_1.ZERO)) {
            return new _1.BN(-1);
        }
        const liqPriceDelta = freeCollateral
            .mul(numericConstants_1.QUOTE_PRECISION)
            .div(freeCollateralDelta);
        const liqPrice = oraclePrice.sub(liqPriceDelta);
        if (liqPrice.lt(numericConstants_1.ZERO)) {
            return new _1.BN(-1);
        }
        return liqPrice;
    }
    /**
     * Calculate the liquidation price of a perp position, with optional parameter to calculate the liquidation price after a trade
     * @param marketIndex
     * @param positionBaseSizeChange // change in position size to calculate liquidation price for : Precision 10^9
     * @param estimatedEntryPrice
     * @param marginCategory // allow Initial to be passed in if we are trying to calculate price for DLP de-risking
     * @param includeOpenOrders
     * @param offsetCollateral // allows calculating the liquidation price after this offset collateral is added to the user's account (e.g. : what will the liquidation price be for this position AFTER I deposit $x worth of collateral)
     * @returns Precision : PRICE_PRECISION
     */
    liquidationPrice(marketIndex, positionBaseSizeChange = numericConstants_1.ZERO, estimatedEntryPrice = numericConstants_1.ZERO, marginCategory = 'Maintenance', includeOpenOrders = false, offsetCollateral = numericConstants_1.ZERO) {
        const totalCollateral = this.getTotalCollateral(marginCategory);
        const marginRequirement = this.getMarginRequirement(marginCategory, undefined, false, includeOpenOrders);
        let freeCollateral = _1.BN.max(numericConstants_1.ZERO, totalCollateral.sub(marginRequirement)).add(offsetCollateral);
        const oracle = this.driftClient.getPerpMarketAccount(marketIndex).amm.oracle;
        const oraclePrice = this.driftClient.getOracleDataForPerpMarket(marketIndex).price;
        const market = this.driftClient.getPerpMarketAccount(marketIndex);
        const currentPerpPosition = this.getPerpPositionWithLPSettle(marketIndex, undefined, true)[0] ||
            this.getEmptyPosition(marketIndex);
        positionBaseSizeChange = (0, _1.standardizeBaseAssetAmount)(positionBaseSizeChange, market.amm.orderStepSize);
        const freeCollateralChangeFromNewPosition = this.calculateEntriesEffectOnFreeCollateral(market, oraclePrice, currentPerpPosition, positionBaseSizeChange, estimatedEntryPrice, includeOpenOrders);
        freeCollateral = freeCollateral.add(freeCollateralChangeFromNewPosition);
        let freeCollateralDelta = this.calculateFreeCollateralDeltaForPerp(market, currentPerpPosition, positionBaseSizeChange, oraclePrice, marginCategory, includeOpenOrders);
        if (!freeCollateralDelta) {
            return new _1.BN(-1);
        }
        const spotMarketWithSameOracle = this.driftClient
            .getSpotMarketAccounts()
            .find((market) => market.oracle.equals(oracle));
        if (spotMarketWithSameOracle) {
            const spotPosition = this.getSpotPosition(spotMarketWithSameOracle.marketIndex);
            if (spotPosition) {
                const signedTokenAmount = (0, _1.getSignedTokenAmount)((0, spotBalance_1.getTokenAmount)(spotPosition.scaledBalance, spotMarketWithSameOracle, spotPosition.balanceType), spotPosition.balanceType);
                const spotFreeCollateralDelta = this.calculateFreeCollateralDeltaForSpot(spotMarketWithSameOracle, signedTokenAmount, marginCategory);
                freeCollateralDelta = freeCollateralDelta.add(spotFreeCollateralDelta || numericConstants_1.ZERO);
            }
        }
        if (freeCollateralDelta.eq(numericConstants_1.ZERO)) {
            return new _1.BN(-1);
        }
        const liqPriceDelta = freeCollateral
            .mul(numericConstants_1.QUOTE_PRECISION)
            .div(freeCollateralDelta);
        const liqPrice = oraclePrice.sub(liqPriceDelta);
        if (liqPrice.lt(numericConstants_1.ZERO)) {
            return new _1.BN(-1);
        }
        return liqPrice;
    }
    calculateEntriesEffectOnFreeCollateral(market, oraclePrice, perpPosition, positionBaseSizeChange, estimatedEntryPrice, includeOpenOrders) {
        let freeCollateralChange = numericConstants_1.ZERO;
        // update free collateral to account for change in pnl from new position
        if (!estimatedEntryPrice.eq(numericConstants_1.ZERO) && !positionBaseSizeChange.eq(numericConstants_1.ZERO)) {
            const costBasis = oraclePrice
                .mul(positionBaseSizeChange.abs())
                .div(numericConstants_1.BASE_PRECISION);
            const newPositionValue = estimatedEntryPrice
                .mul(positionBaseSizeChange.abs())
                .div(numericConstants_1.BASE_PRECISION);
            if (positionBaseSizeChange.gt(numericConstants_1.ZERO)) {
                freeCollateralChange = costBasis.sub(newPositionValue);
            }
            else {
                freeCollateralChange = newPositionValue.sub(costBasis);
            }
            // assume worst fee tier
            const takerFeeTier = this.driftClient.getStateAccount().perpFeeStructure.feeTiers[0];
            const takerFee = newPositionValue
                .muln(takerFeeTier.feeNumerator)
                .divn(takerFeeTier.feeDenominator);
            freeCollateralChange = freeCollateralChange.sub(takerFee);
        }
        const calculateMarginRequirement = (perpPosition) => {
            let baseAssetAmount;
            let liabilityValue;
            if (includeOpenOrders) {
                const { worstCaseBaseAssetAmount, worstCaseLiabilityValue } = (0, _1.calculateWorstCasePerpLiabilityValue)(perpPosition, market, oraclePrice);
                baseAssetAmount = worstCaseBaseAssetAmount;
                liabilityValue = worstCaseLiabilityValue;
            }
            else {
                baseAssetAmount = perpPosition.baseAssetAmount;
                liabilityValue = (0, _1.calculatePerpLiabilityValue)(baseAssetAmount, oraclePrice, (0, types_1.isVariant)(market.contractType, 'prediction'));
            }
            const marginRatio = (0, _1.calculateMarketMarginRatio)(market, baseAssetAmount.abs(), 'Maintenance');
            return liabilityValue.mul(new _1.BN(marginRatio)).div(numericConstants_1.MARGIN_PRECISION);
        };
        const freeCollateralConsumptionBefore = calculateMarginRequirement(perpPosition);
        const perpPositionAfter = Object.assign({}, perpPosition);
        perpPositionAfter.baseAssetAmount = perpPositionAfter.baseAssetAmount.add(positionBaseSizeChange);
        const freeCollateralConsumptionAfter = calculateMarginRequirement(perpPositionAfter);
        return freeCollateralChange.sub(freeCollateralConsumptionAfter.sub(freeCollateralConsumptionBefore));
    }
    calculateFreeCollateralDeltaForPerp(market, perpPosition, positionBaseSizeChange, oraclePrice, marginCategory = 'Maintenance', includeOpenOrders = false) {
        const baseAssetAmount = includeOpenOrders
            ? (0, margin_1.calculateWorstCaseBaseAssetAmount)(perpPosition, market, oraclePrice)
            : perpPosition.baseAssetAmount;
        // zero if include orders == false
        const orderBaseAssetAmount = baseAssetAmount.sub(perpPosition.baseAssetAmount);
        const proposedBaseAssetAmount = baseAssetAmount.add(positionBaseSizeChange);
        const marginRatio = (0, _1.calculateMarketMarginRatio)(market, proposedBaseAssetAmount.abs(), marginCategory, this.getUserAccount().maxMarginRatio);
        const marginRatioQuotePrecision = new _1.BN(marginRatio)
            .mul(numericConstants_1.QUOTE_PRECISION)
            .div(numericConstants_1.MARGIN_PRECISION);
        if (proposedBaseAssetAmount.eq(numericConstants_1.ZERO)) {
            return undefined;
        }
        let freeCollateralDelta = numericConstants_1.ZERO;
        if ((0, types_1.isVariant)(market.contractType, 'prediction')) {
            // for prediction market, increase in pnl and margin requirement will net out for position
            // open order margin requirement will change with price though
            if (orderBaseAssetAmount.gt(numericConstants_1.ZERO)) {
                freeCollateralDelta = marginRatioQuotePrecision.neg();
            }
            else if (orderBaseAssetAmount.lt(numericConstants_1.ZERO)) {
                freeCollateralDelta = marginRatioQuotePrecision;
            }
        }
        else {
            if (proposedBaseAssetAmount.gt(numericConstants_1.ZERO)) {
                freeCollateralDelta = numericConstants_1.QUOTE_PRECISION.sub(marginRatioQuotePrecision)
                    .mul(proposedBaseAssetAmount)
                    .div(numericConstants_1.BASE_PRECISION);
            }
            else {
                freeCollateralDelta = numericConstants_1.QUOTE_PRECISION.neg()
                    .sub(marginRatioQuotePrecision)
                    .mul(proposedBaseAssetAmount.abs())
                    .div(numericConstants_1.BASE_PRECISION);
            }
            if (!orderBaseAssetAmount.eq(numericConstants_1.ZERO)) {
                freeCollateralDelta = freeCollateralDelta.sub(marginRatioQuotePrecision
                    .mul(orderBaseAssetAmount.abs())
                    .div(numericConstants_1.BASE_PRECISION));
            }
        }
        return freeCollateralDelta;
    }
    calculateFreeCollateralDeltaForSpot(market, signedTokenAmount, marginCategory = 'Maintenance') {
        const tokenPrecision = new _1.BN(Math.pow(10, market.decimals));
        if (signedTokenAmount.gt(numericConstants_1.ZERO)) {
            const assetWeight = (0, spotBalance_1.calculateAssetWeight)(signedTokenAmount, this.driftClient.getOracleDataForSpotMarket(market.marketIndex).price, market, marginCategory);
            return numericConstants_1.QUOTE_PRECISION.mul(assetWeight)
                .div(numericConstants_1.SPOT_MARKET_WEIGHT_PRECISION)
                .mul(signedTokenAmount)
                .div(tokenPrecision);
        }
        else {
            const liabilityWeight = (0, spotBalance_1.calculateLiabilityWeight)(signedTokenAmount.abs(), market, marginCategory);
            return numericConstants_1.QUOTE_PRECISION.neg()
                .mul(liabilityWeight)
                .div(numericConstants_1.SPOT_MARKET_WEIGHT_PRECISION)
                .mul(signedTokenAmount.abs())
                .div(tokenPrecision);
        }
    }
    /**
     * Calculates the estimated liquidation price for a position after closing a quote amount of the position.
     * @param positionMarketIndex
     * @param closeQuoteAmount
     * @returns : Precision PRICE_PRECISION
     */
    liquidationPriceAfterClose(positionMarketIndex, closeQuoteAmount, estimatedEntryPrice = numericConstants_1.ZERO) {
        const currentPosition = this.getPerpPositionWithLPSettle(positionMarketIndex, undefined, true)[0] || this.getEmptyPosition(positionMarketIndex);
        const closeBaseAmount = currentPosition.baseAssetAmount
            .mul(closeQuoteAmount)
            .div(currentPosition.quoteAssetAmount.abs())
            .add(currentPosition.baseAssetAmount
            .mul(closeQuoteAmount)
            .mod(currentPosition.quoteAssetAmount.abs()))
            .neg();
        return this.liquidationPrice(positionMarketIndex, closeBaseAmount, estimatedEntryPrice);
    }
    getMarginUSDCRequiredForTrade(targetMarketIndex, baseSize) {
        return (0, margin_1.calculateMarginUSDCRequiredForTrade)(this.driftClient, targetMarketIndex, baseSize, this.getUserAccount().maxMarginRatio);
    }
    getCollateralDepositRequiredForTrade(targetMarketIndex, baseSize, collateralIndex) {
        return (0, margin_1.calculateCollateralDepositRequiredForTrade)(this.driftClient, targetMarketIndex, baseSize, collateralIndex, this.getUserAccount().maxMarginRatio);
    }
    /**
     * Get the maximum trade size for a given market, taking into account the user's current leverage, positions, collateral, etc.
     *
     * To Calculate Max Quote Available:
     *
     * Case 1: SameSide
     * 	=> Remaining quote to get to maxLeverage
     *
     * Case 2: NOT SameSide && currentLeverage <= maxLeverage
     * 	=> Current opposite position x2 + remaining to get to maxLeverage
     *
     * Case 3: NOT SameSide && currentLeverage > maxLeverage && otherPositions - currentPosition > maxLeverage
     * 	=> strictly reduce current position size
     *
     * Case 4: NOT SameSide && currentLeverage > maxLeverage && otherPositions - currentPosition < maxLeverage
     * 	=> current position + remaining to get to maxLeverage
     *
     * @param targetMarketIndex
     * @param tradeSide
     * @param isLp
     * @returns { tradeSize: BN, oppositeSideTradeSize: BN} : Precision QUOTE_PRECISION
     */
    getMaxTradeSizeUSDCForPerp(targetMarketIndex, tradeSide, isLp = false) {
        let tradeSize = numericConstants_1.ZERO;
        let oppositeSideTradeSize = numericConstants_1.ZERO;
        const currentPosition = this.getPerpPositionWithLPSettle(targetMarketIndex, undefined, true)[0] ||
            this.getEmptyPosition(targetMarketIndex);
        const targetSide = (0, types_1.isVariant)(tradeSide, 'short') ? 'short' : 'long';
        const currentPositionSide = (currentPosition === null || currentPosition === void 0 ? void 0 : currentPosition.baseAssetAmount.isNeg())
            ? 'short'
            : 'long';
        const targetingSameSide = !currentPosition
            ? true
            : targetSide === currentPositionSide;
        const oracleData = this.getOracleDataForPerpMarket(targetMarketIndex);
        const marketAccount = this.driftClient.getPerpMarketAccount(targetMarketIndex);
        const lpBuffer = isLp
            ? oracleData.price
                .mul(marketAccount.amm.orderStepSize)
                .div(numericConstants_1.AMM_RESERVE_PRECISION)
            : numericConstants_1.ZERO;
        // add any position we have on the opposite side of the current trade, because we can "flip" the size of this position without taking any extra leverage.
        const oppositeSizeLiabilityValue = targetingSameSide
            ? numericConstants_1.ZERO
            : (0, _1.calculatePerpLiabilityValue)(currentPosition.baseAssetAmount, oracleData.price, (0, types_1.isVariant)(marketAccount.contractType, 'prediction'));
        const maxPositionSize = this.getPerpBuyingPower(targetMarketIndex, lpBuffer);
        if (maxPositionSize.gte(numericConstants_1.ZERO)) {
            if (oppositeSizeLiabilityValue.eq(numericConstants_1.ZERO)) {
                // case 1 : Regular trade where current total position less than max, and no opposite position to account for
                // do nothing
                tradeSize = maxPositionSize;
            }
            else {
                // case 2 : trade where current total position less than max, but need to account for flipping the current position over to the other side
                tradeSize = maxPositionSize.add(oppositeSizeLiabilityValue);
                oppositeSideTradeSize = oppositeSizeLiabilityValue;
            }
        }
        else {
            // current leverage is greater than max leverage - can only reduce position size
            if (!targetingSameSide) {
                const market = this.driftClient.getPerpMarketAccount(targetMarketIndex);
                const perpLiabilityValue = (0, _1.calculatePerpLiabilityValue)(currentPosition.baseAssetAmount, oracleData.price, (0, types_1.isVariant)(market.contractType, 'prediction'));
                const totalCollateral = this.getTotalCollateral();
                const marginRequirement = this.getInitialMarginRequirement();
                const marginFreedByClosing = perpLiabilityValue
                    .mul(new _1.BN(market.marginRatioInitial))
                    .div(numericConstants_1.MARGIN_PRECISION);
                const marginRequirementAfterClosing = marginRequirement.sub(marginFreedByClosing);
                if (marginRequirementAfterClosing.gt(totalCollateral)) {
                    oppositeSideTradeSize = perpLiabilityValue;
                }
                else {
                    const freeCollateralAfterClose = totalCollateral.sub(marginRequirementAfterClosing);
                    const buyingPowerAfterClose = this.getPerpBuyingPowerFromFreeCollateralAndBaseAssetAmount(targetMarketIndex, freeCollateralAfterClose, numericConstants_1.ZERO);
                    oppositeSideTradeSize = perpLiabilityValue;
                    tradeSize = buyingPowerAfterClose;
                }
            }
            else {
                // do nothing if targetting same side
                tradeSize = maxPositionSize;
            }
        }
        return { tradeSize, oppositeSideTradeSize };
    }
    /**
     * Get the maximum trade size for a given market, taking into account the user's current leverage, positions, collateral, etc.
     *
     * @param targetMarketIndex
     * @param direction
     * @param currentQuoteAssetValue
     * @param currentSpotMarketNetValue
     * @returns tradeSizeAllowed : Precision QUOTE_PRECISION
     */
    getMaxTradeSizeUSDCForSpot(targetMarketIndex, direction, currentQuoteAssetValue, currentSpotMarketNetValue) {
        const market = this.driftClient.getSpotMarketAccount(targetMarketIndex);
        const oraclePrice = this.driftClient.getOracleDataForSpotMarket(targetMarketIndex).price;
        currentQuoteAssetValue = this.getSpotMarketAssetValue(numericConstants_1.QUOTE_SPOT_MARKET_INDEX);
        currentSpotMarketNetValue =
            currentSpotMarketNetValue !== null && currentSpotMarketNetValue !== void 0 ? currentSpotMarketNetValue : this.getSpotPositionValue(targetMarketIndex);
        let freeCollateral = this.getFreeCollateral();
        const marginRatio = (0, _1.calculateSpotMarketMarginRatio)(market, oraclePrice, 'Initial', numericConstants_1.ZERO, (0, types_1.isVariant)(direction, 'long')
            ? _1.SpotBalanceType.DEPOSIT
            : _1.SpotBalanceType.BORROW, this.getUserAccount().maxMarginRatio);
        let tradeAmount = numericConstants_1.ZERO;
        if (this.getUserAccount().isMarginTradingEnabled) {
            // if the user is buying/selling and already short/long, need to account for closing out short/long
            if ((0, types_1.isVariant)(direction, 'long') && currentSpotMarketNetValue.lt(numericConstants_1.ZERO)) {
                tradeAmount = currentSpotMarketNetValue.abs();
                const marginRatio = (0, _1.calculateSpotMarketMarginRatio)(market, oraclePrice, 'Initial', this.getTokenAmount(targetMarketIndex).abs(), _1.SpotBalanceType.BORROW, this.getUserAccount().maxMarginRatio);
                freeCollateral = freeCollateral.add(tradeAmount.mul(new _1.BN(marginRatio)).div(numericConstants_1.MARGIN_PRECISION));
            }
            else if ((0, types_1.isVariant)(direction, 'short') &&
                currentSpotMarketNetValue.gt(numericConstants_1.ZERO)) {
                tradeAmount = currentSpotMarketNetValue;
                const marginRatio = (0, _1.calculateSpotMarketMarginRatio)(market, oraclePrice, 'Initial', this.getTokenAmount(targetMarketIndex), _1.SpotBalanceType.DEPOSIT, this.getUserAccount().maxMarginRatio);
                freeCollateral = freeCollateral.add(tradeAmount.mul(new _1.BN(marginRatio)).div(numericConstants_1.MARGIN_PRECISION));
            }
            tradeAmount = tradeAmount.add(freeCollateral.mul(numericConstants_1.MARGIN_PRECISION).div(new _1.BN(marginRatio)));
        }
        else if ((0, types_1.isVariant)(direction, 'long')) {
            tradeAmount = _1.BN.min(currentQuoteAssetValue, freeCollateral.mul(numericConstants_1.MARGIN_PRECISION).div(new _1.BN(marginRatio)));
        }
        else {
            tradeAmount = _1.BN.max(numericConstants_1.ZERO, currentSpotMarketNetValue);
        }
        return tradeAmount;
    }
    /**
     * Calculates the max amount of token that can be swapped from inMarket to outMarket
     * Assumes swap happens at oracle price
     *
     * @param inMarketIndex
     * @param outMarketIndex
     * @param calculateSwap function to similate in to out swa
     * @param iterationLimit how long to run appromixation before erroring out
     */
    getMaxSwapAmount({ inMarketIndex, outMarketIndex, calculateSwap, iterationLimit = 1000, }) {
        const inMarket = this.driftClient.getSpotMarketAccount(inMarketIndex);
        const outMarket = this.driftClient.getSpotMarketAccount(outMarketIndex);
        const inOraclePriceData = this.getOracleDataForSpotMarket(inMarketIndex);
        const inOraclePrice = inOraclePriceData.price;
        const outOraclePriceData = this.getOracleDataForSpotMarket(outMarketIndex);
        const outOraclePrice = outOraclePriceData.price;
        const inStrictOraclePrice = new strictOraclePrice_1.StrictOraclePrice(inOraclePrice);
        const outStrictOraclePrice = new strictOraclePrice_1.StrictOraclePrice(outOraclePrice);
        const inPrecision = new _1.BN(10 ** inMarket.decimals);
        const outPrecision = new _1.BN(10 ** outMarket.decimals);
        const inSpotPosition = this.getSpotPosition(inMarketIndex) ||
            this.getEmptySpotPosition(inMarketIndex);
        const outSpotPosition = this.getSpotPosition(outMarketIndex) ||
            this.getEmptySpotPosition(outMarketIndex);
        const freeCollateral = this.getFreeCollateral();
        const inContributionInitial = this.calculateSpotPositionFreeCollateralContribution(inSpotPosition, inStrictOraclePrice);
        const { totalAssetValue: inTotalAssetValueInitial, totalLiabilityValue: inTotalLiabilityValueInitial, } = this.calculateSpotPositionLeverageContribution(inSpotPosition, inStrictOraclePrice);
        const outContributionInitial = this.calculateSpotPositionFreeCollateralContribution(outSpotPosition, outStrictOraclePrice);
        const { totalAssetValue: outTotalAssetValueInitial, totalLiabilityValue: outTotalLiabilityValueInitial, } = this.calculateSpotPositionLeverageContribution(outSpotPosition, outStrictOraclePrice);
        const initialContribution = inContributionInitial.add(outContributionInitial);
        const { perpLiabilityValue, perpPnl, spotAssetValue, spotLiabilityValue } = this.getLeverageComponents();
        if (!calculateSwap) {
            calculateSwap = (inSwap) => {
                return inSwap
                    .mul(outPrecision)
                    .mul(inOraclePrice)
                    .div(outOraclePrice)
                    .div(inPrecision);
            };
        }
        let inSwap = numericConstants_1.ZERO;
        let outSwap = numericConstants_1.ZERO;
        const inTokenAmount = this.getTokenAmount(inMarketIndex);
        const outTokenAmount = this.getTokenAmount(outMarketIndex);
        const outSaferThanIn = 
        // selling asset to close borrow
        (inTokenAmount.gt(numericConstants_1.ZERO) && outTokenAmount.lt(numericConstants_1.ZERO)) ||
            // buying asset with higher initial asset weight
            inMarket.initialAssetWeight < outMarket.initialAssetWeight;
        if (freeCollateral.lt(numericConstants_1.ONE)) {
            if (outSaferThanIn && inTokenAmount.gt(numericConstants_1.ZERO)) {
                inSwap = inTokenAmount;
                outSwap = calculateSwap(inSwap);
            }
        }
        else {
            let minSwap = numericConstants_1.ZERO;
            let maxSwap = _1.BN.max(freeCollateral.mul(inPrecision).mul(new _1.BN(100)).div(inOraclePrice), // 100x current free collateral
            inTokenAmount.abs().mul(new _1.BN(10)) // 10x current position
            );
            inSwap = maxSwap.div(numericConstants_1.TWO);
            const error = freeCollateral.div(new _1.BN(10000));
            let i = 0;
            let freeCollateralAfter = freeCollateral;
            while (freeCollateralAfter.gt(error) || freeCollateralAfter.isNeg()) {
                outSwap = calculateSwap(inSwap);
                const inPositionAfter = this.cloneAndUpdateSpotPosition(inSpotPosition, inSwap.neg(), inMarket);
                const outPositionAfter = this.cloneAndUpdateSpotPosition(outSpotPosition, outSwap, outMarket);
                const inContributionAfter = this.calculateSpotPositionFreeCollateralContribution(inPositionAfter, inStrictOraclePrice);
                const outContributionAfter = this.calculateSpotPositionFreeCollateralContribution(outPositionAfter, outStrictOraclePrice);
                const contributionAfter = inContributionAfter.add(outContributionAfter);
                const contributionDelta = contributionAfter.sub(initialContribution);
                freeCollateralAfter = freeCollateral.add(contributionDelta);
                if (freeCollateralAfter.gt(error)) {
                    minSwap = inSwap;
                    inSwap = minSwap.add(maxSwap).div(numericConstants_1.TWO);
                }
                else if (freeCollateralAfter.isNeg()) {
                    maxSwap = inSwap;
                    inSwap = minSwap.add(maxSwap).div(numericConstants_1.TWO);
                }
                if (i++ > iterationLimit) {
                    console.log('getMaxSwapAmount iteration limit reached');
                    break;
                }
            }
        }
        const inPositionAfter = this.cloneAndUpdateSpotPosition(inSpotPosition, inSwap.neg(), inMarket);
        const outPositionAfter = this.cloneAndUpdateSpotPosition(outSpotPosition, outSwap, outMarket);
        const { totalAssetValue: inTotalAssetValueAfter, totalLiabilityValue: inTotalLiabilityValueAfter, } = this.calculateSpotPositionLeverageContribution(inPositionAfter, inStrictOraclePrice);
        const { totalAssetValue: outTotalAssetValueAfter, totalLiabilityValue: outTotalLiabilityValueAfter, } = this.calculateSpotPositionLeverageContribution(outPositionAfter, outStrictOraclePrice);
        const spotAssetValueDelta = inTotalAssetValueAfter
            .add(outTotalAssetValueAfter)
            .sub(inTotalAssetValueInitial)
            .sub(outTotalAssetValueInitial);
        const spotLiabilityValueDelta = inTotalLiabilityValueAfter
            .add(outTotalLiabilityValueAfter)
            .sub(inTotalLiabilityValueInitial)
            .sub(outTotalLiabilityValueInitial);
        const spotAssetValueAfter = spotAssetValue.add(spotAssetValueDelta);
        const spotLiabilityValueAfter = spotLiabilityValue.add(spotLiabilityValueDelta);
        const leverage = this.calculateLeverageFromComponents({
            perpLiabilityValue,
            perpPnl,
            spotAssetValue: spotAssetValueAfter,
            spotLiabilityValue: spotLiabilityValueAfter,
        });
        return { inAmount: inSwap, outAmount: outSwap, leverage };
    }
    cloneAndUpdateSpotPosition(position, tokenAmount, market) {
        const clonedPosition = Object.assign({}, position);
        if (tokenAmount.eq(numericConstants_1.ZERO)) {
            return clonedPosition;
        }
        const preTokenAmount = (0, _1.getSignedTokenAmount)((0, spotBalance_1.getTokenAmount)(position.scaledBalance, market, position.balanceType), position.balanceType);
        if ((0, _1.sigNum)(preTokenAmount).eq((0, _1.sigNum)(tokenAmount))) {
            const scaledBalanceDelta = (0, _1.getBalance)(tokenAmount.abs(), market, position.balanceType);
            clonedPosition.scaledBalance =
                clonedPosition.scaledBalance.add(scaledBalanceDelta);
            return clonedPosition;
        }
        const updateDirection = tokenAmount.isNeg()
            ? _1.SpotBalanceType.BORROW
            : _1.SpotBalanceType.DEPOSIT;
        if (tokenAmount.abs().gte(preTokenAmount.abs())) {
            clonedPosition.scaledBalance = (0, _1.getBalance)(tokenAmount.abs().sub(preTokenAmount.abs()), market, updateDirection);
            clonedPosition.balanceType = updateDirection;
        }
        else {
            const scaledBalanceDelta = (0, _1.getBalance)(tokenAmount.abs(), market, position.balanceType);
            clonedPosition.scaledBalance =
                clonedPosition.scaledBalance.sub(scaledBalanceDelta);
        }
        return clonedPosition;
    }
    calculateSpotPositionFreeCollateralContribution(spotPosition, strictOraclePrice) {
        const marginCategory = 'Initial';
        const spotMarketAccount = this.driftClient.getSpotMarketAccount(spotPosition.marketIndex);
        const { freeCollateralContribution } = (0, spotPosition_1.getWorstCaseTokenAmounts)(spotPosition, spotMarketAccount, strictOraclePrice, marginCategory, this.getUserAccount().maxMarginRatio);
        return freeCollateralContribution;
    }
    calculateSpotPositionLeverageContribution(spotPosition, strictOraclePrice) {
        let totalAssetValue = numericConstants_1.ZERO;
        let totalLiabilityValue = numericConstants_1.ZERO;
        const spotMarketAccount = this.driftClient.getSpotMarketAccount(spotPosition.marketIndex);
        const { tokenValue, ordersValue } = (0, spotPosition_1.getWorstCaseTokenAmounts)(spotPosition, spotMarketAccount, strictOraclePrice, 'Initial', this.getUserAccount().maxMarginRatio);
        if (tokenValue.gte(numericConstants_1.ZERO)) {
            totalAssetValue = tokenValue;
        }
        else {
            totalLiabilityValue = tokenValue.abs();
        }
        if (ordersValue.gt(numericConstants_1.ZERO)) {
            totalAssetValue = totalAssetValue.add(ordersValue);
        }
        else {
            totalLiabilityValue = totalLiabilityValue.add(ordersValue.abs());
        }
        return {
            totalAssetValue,
            totalLiabilityValue,
        };
    }
    /**
     * Estimates what the user leverage will be after swap
     * @param inMarketIndex
     * @param outMarketIndex
     * @param inAmount
     * @param outAmount
     */
    accountLeverageAfterSwap({ inMarketIndex, outMarketIndex, inAmount, outAmount, }) {
        const inMarket = this.driftClient.getSpotMarketAccount(inMarketIndex);
        const outMarket = this.driftClient.getSpotMarketAccount(outMarketIndex);
        const inOraclePriceData = this.getOracleDataForSpotMarket(inMarketIndex);
        const inOraclePrice = inOraclePriceData.price;
        const outOraclePriceData = this.getOracleDataForSpotMarket(outMarketIndex);
        const outOraclePrice = outOraclePriceData.price;
        const inStrictOraclePrice = new strictOraclePrice_1.StrictOraclePrice(inOraclePrice);
        const outStrictOraclePrice = new strictOraclePrice_1.StrictOraclePrice(outOraclePrice);
        const inSpotPosition = this.getSpotPosition(inMarketIndex) ||
            this.getEmptySpotPosition(inMarketIndex);
        const outSpotPosition = this.getSpotPosition(outMarketIndex) ||
            this.getEmptySpotPosition(outMarketIndex);
        const { totalAssetValue: inTotalAssetValueInitial, totalLiabilityValue: inTotalLiabilityValueInitial, } = this.calculateSpotPositionLeverageContribution(inSpotPosition, inStrictOraclePrice);
        const { totalAssetValue: outTotalAssetValueInitial, totalLiabilityValue: outTotalLiabilityValueInitial, } = this.calculateSpotPositionLeverageContribution(outSpotPosition, outStrictOraclePrice);
        const { perpLiabilityValue, perpPnl, spotAssetValue, spotLiabilityValue } = this.getLeverageComponents();
        const inPositionAfter = this.cloneAndUpdateSpotPosition(inSpotPosition, inAmount.abs().neg(), inMarket);
        const outPositionAfter = this.cloneAndUpdateSpotPosition(outSpotPosition, outAmount.abs(), outMarket);
        const { totalAssetValue: inTotalAssetValueAfter, totalLiabilityValue: inTotalLiabilityValueAfter, } = this.calculateSpotPositionLeverageContribution(inPositionAfter, inStrictOraclePrice);
        const { totalAssetValue: outTotalAssetValueAfter, totalLiabilityValue: outTotalLiabilityValueAfter, } = this.calculateSpotPositionLeverageContribution(outPositionAfter, outStrictOraclePrice);
        const spotAssetValueDelta = inTotalAssetValueAfter
            .add(outTotalAssetValueAfter)
            .sub(inTotalAssetValueInitial)
            .sub(outTotalAssetValueInitial);
        const spotLiabilityValueDelta = inTotalLiabilityValueAfter
            .add(outTotalLiabilityValueAfter)
            .sub(inTotalLiabilityValueInitial)
            .sub(outTotalLiabilityValueInitial);
        const spotAssetValueAfter = spotAssetValue.add(spotAssetValueDelta);
        const spotLiabilityValueAfter = spotLiabilityValue.add(spotLiabilityValueDelta);
        return this.calculateLeverageFromComponents({
            perpLiabilityValue,
            perpPnl,
            spotAssetValue: spotAssetValueAfter,
            spotLiabilityValue: spotLiabilityValueAfter,
        });
    }
    // TODO - should this take the price impact of the trade into account for strict accuracy?
    /**
     * Returns the leverage ratio for the account after adding (or subtracting) the given quote size to the given position
     * @param targetMarketIndex
     * @param: targetMarketType
     * @param tradeQuoteAmount
     * @param tradeSide
     * @param includeOpenOrders
     * @returns leverageRatio : Precision TEN_THOUSAND
     */
    accountLeverageRatioAfterTrade(targetMarketIndex, targetMarketType, tradeQuoteAmount, tradeSide, includeOpenOrders = true) {
        const tradeIsPerp = (0, types_1.isVariant)(targetMarketType, 'perp');
        if (!tradeIsPerp) {
            // calculate new asset/liability values for base and quote market to find new account leverage
            const totalLiabilityValue = this.getTotalLiabilityValue();
            const totalAssetValue = this.getTotalAssetValue();
            const spotLiabilityValue = this.getSpotMarketLiabilityValue(undefined, undefined, undefined, includeOpenOrders);
            const currentQuoteAssetValue = this.getSpotMarketAssetValue(numericConstants_1.QUOTE_SPOT_MARKET_INDEX, undefined, includeOpenOrders);
            const currentQuoteLiabilityValue = this.getSpotMarketLiabilityValue(numericConstants_1.QUOTE_SPOT_MARKET_INDEX, undefined, undefined, includeOpenOrders);
            const currentQuoteValue = currentQuoteAssetValue.sub(currentQuoteLiabilityValue);
            const currentSpotMarketAssetValue = this.getSpotMarketAssetValue(targetMarketIndex, undefined, includeOpenOrders);
            const currentSpotMarketLiabilityValue = this.getSpotMarketLiabilityValue(targetMarketIndex, undefined, undefined, includeOpenOrders);
            const currentSpotMarketNetValue = currentSpotMarketAssetValue.sub(currentSpotMarketLiabilityValue);
            let assetValueToAdd = numericConstants_1.ZERO;
            let liabilityValueToAdd = numericConstants_1.ZERO;
            const newQuoteNetValue = tradeSide == _1.PositionDirection.SHORT
                ? currentQuoteValue.add(tradeQuoteAmount)
                : currentQuoteValue.sub(tradeQuoteAmount);
            const newQuoteAssetValue = _1.BN.max(newQuoteNetValue, numericConstants_1.ZERO);
            const newQuoteLiabilityValue = _1.BN.min(newQuoteNetValue, numericConstants_1.ZERO).abs();
            assetValueToAdd = assetValueToAdd.add(newQuoteAssetValue.sub(currentQuoteAssetValue));
            liabilityValueToAdd = liabilityValueToAdd.add(newQuoteLiabilityValue.sub(currentQuoteLiabilityValue));
            const newSpotMarketNetValue = tradeSide == _1.PositionDirection.LONG
                ? currentSpotMarketNetValue.add(tradeQuoteAmount)
                : currentSpotMarketNetValue.sub(tradeQuoteAmount);
            const newSpotMarketAssetValue = _1.BN.max(newSpotMarketNetValue, numericConstants_1.ZERO);
            const newSpotMarketLiabilityValue = _1.BN.min(newSpotMarketNetValue, numericConstants_1.ZERO).abs();
            assetValueToAdd = assetValueToAdd.add(newSpotMarketAssetValue.sub(currentSpotMarketAssetValue));
            liabilityValueToAdd = liabilityValueToAdd.add(newSpotMarketLiabilityValue.sub(currentSpotMarketLiabilityValue));
            const totalAssetValueAfterTrade = totalAssetValue.add(assetValueToAdd);
            const totalSpotLiabilityValueAfterTrade = spotLiabilityValue.add(liabilityValueToAdd);
            const totalLiabilityValueAfterTrade = totalLiabilityValue.add(liabilityValueToAdd);
            const netAssetValueAfterTrade = totalAssetValueAfterTrade.sub(totalSpotLiabilityValueAfterTrade);
            if (netAssetValueAfterTrade.eq(numericConstants_1.ZERO)) {
                return numericConstants_1.ZERO;
            }
            const newLeverage = totalLiabilityValueAfterTrade
                .mul(numericConstants_1.TEN_THOUSAND)
                .div(netAssetValueAfterTrade);
            return newLeverage;
        }
        const currentPosition = this.getPerpPositionWithLPSettle(targetMarketIndex)[0] ||
            this.getEmptyPosition(targetMarketIndex);
        const perpMarket = this.driftClient.getPerpMarketAccount(targetMarketIndex);
        const oracleData = this.getOracleDataForPerpMarket(targetMarketIndex);
        let { 
        // eslint-disable-next-line prefer-const
        worstCaseBaseAssetAmount: worstCaseBase, worstCaseLiabilityValue: currentPositionQuoteAmount, } = (0, _1.calculateWorstCasePerpLiabilityValue)(currentPosition, perpMarket, oracleData.price);
        // current side is short if position base asset amount is negative OR there is no position open but open orders are short
        const currentSide = currentPosition.baseAssetAmount.isNeg() ||
            (currentPosition.baseAssetAmount.eq(numericConstants_1.ZERO) && worstCaseBase.isNeg())
            ? _1.PositionDirection.SHORT
            : _1.PositionDirection.LONG;
        if (currentSide === _1.PositionDirection.SHORT)
            currentPositionQuoteAmount = currentPositionQuoteAmount.neg();
        if (tradeSide === _1.PositionDirection.SHORT)
            tradeQuoteAmount = tradeQuoteAmount.neg();
        const currentPerpPositionAfterTrade = currentPositionQuoteAmount
            .add(tradeQuoteAmount)
            .abs();
        const totalPositionAfterTradeExcludingTargetMarket = this.getTotalPerpPositionValueExcludingMarket(targetMarketIndex, undefined, undefined, includeOpenOrders);
        const totalAssetValue = this.getTotalAssetValue();
        const totalPerpPositionLiability = currentPerpPositionAfterTrade
            .add(totalPositionAfterTradeExcludingTargetMarket)
            .abs();
        const totalSpotLiability = this.getSpotMarketLiabilityValue(undefined, undefined, undefined, includeOpenOrders);
        const totalLiabilitiesAfterTrade = totalPerpPositionLiability.add(totalSpotLiability);
        const netAssetValue = totalAssetValue.sub(totalSpotLiability);
        if (netAssetValue.eq(numericConstants_1.ZERO)) {
            return numericConstants_1.ZERO;
        }
        const newLeverage = totalLiabilitiesAfterTrade
            .mul(numericConstants_1.TEN_THOUSAND)
            .div(netAssetValue);
        return newLeverage;
    }
    getUserFeeTier(marketType, now) {
        const state = this.driftClient.getStateAccount();
        let feeTierIndex = 0;
        if ((0, types_1.isVariant)(marketType, 'perp')) {
            const userStatsAccount = this.driftClient
                .getUserStats()
                .getAccount();
            const total30dVolume = (0, _1.getUser30dRollingVolumeEstimate)(userStatsAccount, now);
            const stakedQuoteAssetAmount = userStatsAccount.ifStakedQuoteAssetAmount;
            const volumeTiers = [
                new _1.BN(100000000).mul(numericConstants_1.QUOTE_PRECISION),
                new _1.BN(50000000).mul(numericConstants_1.QUOTE_PRECISION),
                new _1.BN(10000000).mul(numericConstants_1.QUOTE_PRECISION),
                new _1.BN(5000000).mul(numericConstants_1.QUOTE_PRECISION),
                new _1.BN(1000000).mul(numericConstants_1.QUOTE_PRECISION),
            ];
            const stakedTiers = [
                new _1.BN(10000).mul(numericConstants_1.QUOTE_PRECISION),
                new _1.BN(5000).mul(numericConstants_1.QUOTE_PRECISION),
                new _1.BN(2000).mul(numericConstants_1.QUOTE_PRECISION),
                new _1.BN(1000).mul(numericConstants_1.QUOTE_PRECISION),
                new _1.BN(500).mul(numericConstants_1.QUOTE_PRECISION),
            ];
            for (let i = 0; i < volumeTiers.length; i++) {
                if (total30dVolume.gte(volumeTiers[i]) ||
                    stakedQuoteAssetAmount.gte(stakedTiers[i])) {
                    feeTierIndex = 5 - i;
                    break;
                }
            }
            return state.perpFeeStructure.feeTiers[feeTierIndex];
        }
        return state.spotFeeStructure.feeTiers[feeTierIndex];
    }
    /**
     * Calculates how much perp fee will be taken for a given sized trade
     * @param quoteAmount
     * @returns feeForQuote : Precision QUOTE_PRECISION
     */
    calculateFeeForQuoteAmount(quoteAmount, marketIndex) {
        if (marketIndex !== undefined) {
            const takerFeeMultiplier = this.driftClient.getMarketFees(_1.MarketType.PERP, marketIndex, this).takerFee;
            const feeAmountNum = _1.BigNum.from(quoteAmount, numericConstants_1.QUOTE_PRECISION_EXP).toNum() *
                takerFeeMultiplier;
            return _1.BigNum.fromPrint(feeAmountNum.toString(), numericConstants_1.QUOTE_PRECISION_EXP).val;
        }
        else {
            const feeTier = this.getUserFeeTier(_1.MarketType.PERP);
            return quoteAmount
                .mul(new _1.BN(feeTier.feeNumerator))
                .div(new _1.BN(feeTier.feeDenominator));
        }
    }
    /**
     * Calculates a user's max withdrawal amounts for a spot market. If reduceOnly is true,
     * it will return the max withdrawal amount without opening a liability for the user
     * @param marketIndex
     * @returns withdrawalLimit : Precision is the token precision for the chosen SpotMarket
     */
    getWithdrawalLimit(marketIndex, reduceOnly) {
        const nowTs = new _1.BN(Math.floor(Date.now() / 1000));
        const spotMarket = this.driftClient.getSpotMarketAccount(marketIndex);
        // eslint-disable-next-line prefer-const
        let { borrowLimit, withdrawLimit } = (0, spotBalance_1.calculateWithdrawLimit)(spotMarket, nowTs);
        const freeCollateral = this.getFreeCollateral();
        const initialMarginRequirement = this.getInitialMarginRequirement();
        const oracleData = this.getOracleDataForSpotMarket(marketIndex);
        const precisionIncrease = numericConstants_1.TEN.pow(new _1.BN(spotMarket.decimals - 6));
        const { canBypass, depositAmount: userDepositAmount } = this.canBypassWithdrawLimits(marketIndex);
        if (canBypass) {
            withdrawLimit = _1.BN.max(withdrawLimit, userDepositAmount);
        }
        const assetWeight = (0, spotBalance_1.calculateAssetWeight)(userDepositAmount, oracleData.price, spotMarket, 'Initial');
        let amountWithdrawable;
        if (assetWeight.eq(numericConstants_1.ZERO)) {
            amountWithdrawable = userDepositAmount;
        }
        else if (initialMarginRequirement.eq(numericConstants_1.ZERO)) {
            amountWithdrawable = userDepositAmount;
        }
        else {
            amountWithdrawable = (0, _1.divCeil)((0, _1.divCeil)(freeCollateral.mul(numericConstants_1.MARGIN_PRECISION), assetWeight).mul(numericConstants_1.PRICE_PRECISION), oracleData.price).mul(precisionIncrease);
        }
        const maxWithdrawValue = _1.BN.min(_1.BN.min(amountWithdrawable, userDepositAmount), withdrawLimit.abs());
        if (reduceOnly) {
            return _1.BN.max(maxWithdrawValue, numericConstants_1.ZERO);
        }
        else {
            const weightedAssetValue = this.getSpotMarketAssetValue(marketIndex, 'Initial', false);
            const freeCollatAfterWithdraw = userDepositAmount.gt(numericConstants_1.ZERO)
                ? freeCollateral.sub(weightedAssetValue)
                : freeCollateral;
            const maxLiabilityAllowed = freeCollatAfterWithdraw
                .mul(numericConstants_1.MARGIN_PRECISION)
                .div(new _1.BN(spotMarket.initialLiabilityWeight))
                .mul(numericConstants_1.PRICE_PRECISION)
                .div(oracleData.price)
                .mul(precisionIncrease);
            const maxBorrowValue = _1.BN.min(maxWithdrawValue.add(maxLiabilityAllowed), borrowLimit.abs());
            return _1.BN.max(maxBorrowValue, numericConstants_1.ZERO);
        }
    }
    canBypassWithdrawLimits(marketIndex) {
        const spotMarket = this.driftClient.getSpotMarketAccount(marketIndex);
        const maxDepositAmount = spotMarket.withdrawGuardThreshold.div(new _1.BN(10));
        const position = this.getSpotPosition(marketIndex);
        const netDeposits = this.getUserAccount().totalDeposits.sub(this.getUserAccount().totalWithdraws);
        if (!position) {
            return {
                canBypass: false,
                maxDepositAmount,
                depositAmount: numericConstants_1.ZERO,
                netDeposits,
            };
        }
        if ((0, types_1.isVariant)(position.balanceType, 'borrow')) {
            return {
                canBypass: false,
                maxDepositAmount,
                netDeposits,
                depositAmount: numericConstants_1.ZERO,
            };
        }
        const depositAmount = (0, spotBalance_1.getTokenAmount)(position.scaledBalance, spotMarket, _1.SpotBalanceType.DEPOSIT);
        if (netDeposits.lt(numericConstants_1.ZERO)) {
            return {
                canBypass: false,
                maxDepositAmount,
                depositAmount,
                netDeposits,
            };
        }
        return {
            canBypass: depositAmount.lt(maxDepositAmount),
            maxDepositAmount,
            netDeposits,
            depositAmount,
        };
    }
    canMakeIdle(slot) {
        const userAccount = this.getUserAccount();
        if (userAccount.idle) {
            return false;
        }
        const { totalAssetValue, totalLiabilityValue } = this.getSpotMarketAssetAndLiabilityValue();
        const equity = totalAssetValue.sub(totalLiabilityValue);
        let slotsBeforeIdle;
        if (equity.lt(numericConstants_1.QUOTE_PRECISION.muln(1000))) {
            slotsBeforeIdle = new _1.BN(9000); // 1 hour
        }
        else {
            slotsBeforeIdle = new _1.BN(1512000); // 1 week
        }
        const userLastActiveSlot = userAccount.lastActiveSlot;
        const slotsSinceLastActive = slot.sub(userLastActiveSlot);
        if (slotsSinceLastActive.lt(slotsBeforeIdle)) {
            return false;
        }
        if (this.isBeingLiquidated()) {
            return false;
        }
        for (const perpPosition of userAccount.perpPositions) {
            if (!(0, position_1.positionIsAvailable)(perpPosition)) {
                return false;
            }
        }
        for (const spotPosition of userAccount.spotPositions) {
            if ((0, types_1.isVariant)(spotPosition.balanceType, 'borrow') &&
                spotPosition.scaledBalance.gt(numericConstants_1.ZERO)) {
                return false;
            }
            if (spotPosition.openOrders !== 0) {
                return false;
            }
        }
        for (const order of userAccount.orders) {
            if (!(0, types_1.isVariant)(order.status, 'init')) {
                return false;
            }
        }
        return true;
    }
    getSafestTiers() {
        let safestPerpTier = 4;
        let safestSpotTier = 4;
        for (const perpPosition of this.getActivePerpPositions()) {
            safestPerpTier = Math.min(safestPerpTier, (0, tiers_1.getPerpMarketTierNumber)(this.driftClient.getPerpMarketAccount(perpPosition.marketIndex)));
        }
        for (const spotPosition of this.getActiveSpotPositions()) {
            if ((0, types_1.isVariant)(spotPosition.balanceType, 'deposit')) {
                continue;
            }
            safestSpotTier = Math.min(safestSpotTier, (0, tiers_1.getSpotMarketTierNumber)(this.driftClient.getSpotMarketAccount(spotPosition.marketIndex)));
        }
        return {
            perpTier: safestPerpTier,
            spotTier: safestSpotTier,
        };
    }
    getPerpPositionHealth({ marginCategory, perpPosition, oraclePriceData, quoteOraclePriceData, }) {
        const settledLpPosition = this.getPerpPositionWithLPSettle(perpPosition.marketIndex, perpPosition)[0];
        const perpMarket = this.driftClient.getPerpMarketAccount(perpPosition.marketIndex);
        const _oraclePriceData = oraclePriceData ||
            this.driftClient.getOracleDataForPerpMarket(perpMarket.marketIndex);
        const oraclePrice = _oraclePriceData.price;
        const { worstCaseBaseAssetAmount: worstCaseBaseAmount, worstCaseLiabilityValue, } = (0, _1.calculateWorstCasePerpLiabilityValue)(settledLpPosition, perpMarket, oraclePrice);
        const marginRatio = new _1.BN((0, _1.calculateMarketMarginRatio)(perpMarket, worstCaseBaseAmount.abs(), marginCategory, this.getUserAccount().maxMarginRatio));
        const _quoteOraclePriceData = quoteOraclePriceData ||
            this.driftClient.getOracleDataForSpotMarket(numericConstants_1.QUOTE_SPOT_MARKET_INDEX);
        let marginRequirement = worstCaseLiabilityValue
            .mul(_quoteOraclePriceData.price)
            .div(numericConstants_1.PRICE_PRECISION)
            .mul(marginRatio)
            .div(numericConstants_1.MARGIN_PRECISION);
        marginRequirement = marginRequirement.add(new _1.BN(perpPosition.openOrders).mul(numericConstants_1.OPEN_ORDER_MARGIN_REQUIREMENT));
        if (perpPosition.lpShares.gt(numericConstants_1.ZERO)) {
            marginRequirement = marginRequirement.add(_1.BN.max(numericConstants_1.QUOTE_PRECISION, oraclePrice
                .mul(perpMarket.amm.orderStepSize)
                .mul(numericConstants_1.QUOTE_PRECISION)
                .div(numericConstants_1.AMM_RESERVE_PRECISION)
                .div(numericConstants_1.PRICE_PRECISION)));
        }
        return {
            marketIndex: perpMarket.marketIndex,
            size: worstCaseBaseAmount,
            value: worstCaseLiabilityValue,
            weight: marginRatio,
            weightedValue: marginRequirement,
        };
    }
    getHealthComponents({ marginCategory, }) {
        const healthComponents = {
            deposits: [],
            borrows: [],
            perpPositions: [],
            perpPnl: [],
        };
        for (const perpPosition of this.getActivePerpPositions()) {
            const perpMarket = this.driftClient.getPerpMarketAccount(perpPosition.marketIndex);
            const oraclePriceData = this.driftClient.getOracleDataForPerpMarket(perpMarket.marketIndex);
            const quoteOraclePriceData = this.driftClient.getOracleDataForSpotMarket(numericConstants_1.QUOTE_SPOT_MARKET_INDEX);
            healthComponents.perpPositions.push(this.getPerpPositionHealth({
                marginCategory,
                perpPosition,
                oraclePriceData,
                quoteOraclePriceData,
            }));
            const quoteSpotMarket = this.driftClient.getSpotMarketAccount(perpMarket.quoteSpotMarketIndex);
            const settledPerpPosition = this.getPerpPositionWithLPSettle(perpPosition.marketIndex, perpPosition)[0];
            const positionUnrealizedPnl = (0, _1.calculatePositionPNL)(perpMarket, settledPerpPosition, true, oraclePriceData);
            let pnlWeight;
            if (positionUnrealizedPnl.gt(numericConstants_1.ZERO)) {
                pnlWeight = (0, _1.calculateUnrealizedAssetWeight)(perpMarket, quoteSpotMarket, positionUnrealizedPnl, marginCategory, oraclePriceData);
            }
            else {
                pnlWeight = numericConstants_1.SPOT_MARKET_WEIGHT_PRECISION;
            }
            const pnlValue = positionUnrealizedPnl
                .mul(quoteOraclePriceData.price)
                .div(numericConstants_1.PRICE_PRECISION);
            const wegithedPnlValue = pnlValue
                .mul(pnlWeight)
                .div(numericConstants_1.SPOT_MARKET_WEIGHT_PRECISION);
            healthComponents.perpPnl.push({
                marketIndex: perpMarket.marketIndex,
                size: positionUnrealizedPnl,
                value: pnlValue,
                weight: pnlWeight,
                weightedValue: wegithedPnlValue,
            });
        }
        let netQuoteValue = numericConstants_1.ZERO;
        for (const spotPosition of this.getActiveSpotPositions()) {
            const spotMarketAccount = this.driftClient.getSpotMarketAccount(spotPosition.marketIndex);
            const oraclePriceData = this.getOracleDataForSpotMarket(spotPosition.marketIndex);
            const strictOraclePrice = new strictOraclePrice_1.StrictOraclePrice(oraclePriceData.price);
            if (spotPosition.marketIndex === numericConstants_1.QUOTE_SPOT_MARKET_INDEX) {
                const tokenAmount = (0, _1.getSignedTokenAmount)((0, spotBalance_1.getTokenAmount)(spotPosition.scaledBalance, spotMarketAccount, spotPosition.balanceType), spotPosition.balanceType);
                netQuoteValue = netQuoteValue.add(tokenAmount);
                continue;
            }
            const { tokenAmount: worstCaseTokenAmount, tokenValue: tokenValue, weight, weightedTokenValue: weightedTokenValue, ordersValue: ordersValue, } = (0, spotPosition_1.getWorstCaseTokenAmounts)(spotPosition, spotMarketAccount, strictOraclePrice, marginCategory, this.getUserAccount().maxMarginRatio);
            netQuoteValue = netQuoteValue.add(ordersValue);
            const baseAssetValue = tokenValue.abs();
            const weightedValue = weightedTokenValue.abs();
            if (weightedTokenValue.lt(numericConstants_1.ZERO)) {
                healthComponents.borrows.push({
                    marketIndex: spotMarketAccount.marketIndex,
                    size: worstCaseTokenAmount,
                    value: baseAssetValue,
                    weight: weight,
                    weightedValue: weightedValue,
                });
            }
            else {
                healthComponents.deposits.push({
                    marketIndex: spotMarketAccount.marketIndex,
                    size: worstCaseTokenAmount,
                    value: baseAssetValue,
                    weight: weight,
                    weightedValue: weightedValue,
                });
            }
        }
        if (!netQuoteValue.eq(numericConstants_1.ZERO)) {
            const spotMarketAccount = this.driftClient.getQuoteSpotMarketAccount();
            const oraclePriceData = this.getOracleDataForSpotMarket(numericConstants_1.QUOTE_SPOT_MARKET_INDEX);
            const baseAssetValue = (0, _1.getTokenValue)(netQuoteValue, spotMarketAccount.decimals, oraclePriceData);
            const { weight, weightedTokenValue } = (0, spotPosition_1.calculateWeightedTokenValue)(netQuoteValue, baseAssetValue, oraclePriceData.price, spotMarketAccount, marginCategory, this.getUserAccount().maxMarginRatio);
            if (netQuoteValue.lt(numericConstants_1.ZERO)) {
                healthComponents.borrows.push({
                    marketIndex: spotMarketAccount.marketIndex,
                    size: netQuoteValue,
                    value: baseAssetValue.abs(),
                    weight: weight,
                    weightedValue: weightedTokenValue.abs(),
                });
            }
            else {
                healthComponents.deposits.push({
                    marketIndex: spotMarketAccount.marketIndex,
                    size: netQuoteValue,
                    value: baseAssetValue,
                    weight: weight,
                    weightedValue: weightedTokenValue,
                });
            }
        }
        return healthComponents;
    }
    /**
     * Get the total position value, excluding any position coming from the given target market
     * @param marketToIgnore
     * @returns positionValue : Precision QUOTE_PRECISION
     */
    getTotalPerpPositionValueExcludingMarket(marketToIgnore, marginCategory, liquidationBuffer, includeOpenOrders) {
        const currentPerpPosition = this.getPerpPositionWithLPSettle(marketToIgnore, undefined, !!marginCategory)[0] || this.getEmptyPosition(marketToIgnore);
        const oracleData = this.getOracleDataForPerpMarket(marketToIgnore);
        let currentPerpPositionValueUSDC = numericConstants_1.ZERO;
        if (currentPerpPosition) {
            currentPerpPositionValueUSDC = this.getPerpLiabilityValue(marketToIgnore, oracleData, includeOpenOrders);
        }
        return this.getTotalPerpPositionLiability(marginCategory, liquidationBuffer, includeOpenOrders).sub(currentPerpPositionValueUSDC);
    }
    getOracleDataForPerpMarket(marketIndex) {
        return this.driftClient.getOracleDataForPerpMarket(marketIndex);
    }
    getOracleDataForSpotMarket(marketIndex) {
        return this.driftClient.getOracleDataForSpotMarket(marketIndex);
    }
}
exports.User = User;
