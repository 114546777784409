"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionConfirmationManager = void 0;
const config_1 = require("../config");
const confirmationStatusValues = {
    processed: 0,
    confirmed: 1,
    finalized: 2,
};
/**
 * Class to await for transaction confirmations in an optimised manner. It tracks a shared list of all pending transactions and fetches them in bulk in a shared RPC request whenever they have an "overlapping" polling interval. E.g. tx1 with an interval of 200ms and tx2 with an interval of 300ms (if sent at the same time) will be fetched together at at 600ms, 1200ms, 1800ms, etc.
 */
class TransactionConfirmationManager {
    constructor(connection) {
        this.pendingConfirmations = new Map();
        this.intervalId = null;
        this.connection = connection;
    }
    async confirmTransaction(txSig, desiredConfirmationStatus = config_1.DEFAULT_CONFIRMATION_OPTS.commitment, timeout = 30000, pollInterval = 1000, searchTransactionHistory = false) {
        // Interval must be > 400ms and a multiple of 100ms
        if (pollInterval < 400 || pollInterval % 100 !== 0) {
            throw new Error('Transaction confirmation polling interval must be at least 400ms and a multiple of 100ms');
        }
        return new Promise((resolve, reject) => {
            this.pendingConfirmations.set(txSig, {
                txSig,
                desiredConfirmationStatus,
                timeout,
                pollInterval,
                searchTransactionHistory,
                startTime: Date.now(),
                resolve,
                reject,
            });
            if (!this.intervalId) {
                this.startConfirmationLoop();
            }
        });
    }
    startConfirmationLoop() {
        this.intervalId = setInterval(() => this.checkPendingConfirmations(), 100);
    }
    async checkPendingConfirmations() {
        const now = Date.now();
        const transactionsToCheck = [];
        for (const [txSig, request] of this.pendingConfirmations.entries()) {
            if (now - request.startTime >= request.timeout) {
                request.reject(new Error(`Transaction confirmation timeout after ${request.timeout}ms`));
                this.pendingConfirmations.delete(txSig);
            }
            else if ((now - request.startTime) % request.pollInterval < 100) {
                transactionsToCheck.push(request);
            }
        }
        if (transactionsToCheck.length > 0) {
            await this.checkTransactionStatuses(transactionsToCheck);
        }
        if (this.pendingConfirmations.size === 0 && this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }
    async checkTransactionStatuses(requests) {
        const txSigs = requests.map((request) => request.txSig);
        const { value: statuses } = await this.connection.getSignatureStatuses(txSigs, {
            searchTransactionHistory: requests.some((req) => req.searchTransactionHistory),
        });
        if (!statuses || statuses.length !== txSigs.length) {
            throw new Error('Failed to get signature statuses');
        }
        for (let i = 0; i < statuses.length; i++) {
            const status = statuses[i];
            const request = requests[i];
            if (status === null) {
                continue;
            }
            if (status.err) {
                request.reject(new Error(`Transaction failed: ${JSON.stringify(status.err)}`));
                this.pendingConfirmations.delete(request.txSig);
                continue;
            }
            if (confirmationStatusValues[status.confirmationStatus] === undefined ||
                confirmationStatusValues[request.desiredConfirmationStatus] ===
                    undefined) {
                throw new Error(`Invalid confirmation status when awaiting confirmation: ${status.confirmationStatus}`);
            }
            if (status.confirmationStatus &&
                confirmationStatusValues[status.confirmationStatus] >=
                    confirmationStatusValues[request.desiredConfirmationStatus]) {
                request.resolve(status);
                this.pendingConfirmations.delete(request.txSig);
            }
        }
    }
}
exports.TransactionConfirmationManager = TransactionConfirmationManager;
